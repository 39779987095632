import { useDispatch, useSelector } from "react-redux"
import { broadcastEvent, getAllApplicationRoles, getUser } from "../common"
import { useMutateData } from "./useMutateData"
import { selectAppRoleUrl } from "../../api/endpoints"
import { login } from "../../redux/slices"
import { getHomePage } from "../../constants/routes"

export const useRoleSwitch = () => {
  const allRoles = useSelector(getAllApplicationRoles)
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const [apiCall] = useMutateData({ showNotification: false })
  const setUserDetails = (userDetails) => dispatch(login(userDetails))
  const switchRole = async ({ key, app = {}, userId = "", switchOperation = true, userDetails }) => {
    window.localStorage.setItem("roleSwitchInProgress", true)
    const newRole = key && allRoles[key] ? allRoles[key] : app
    const { id, role, subdomain, uid = "" } = newRole
    const { data = {} } = await apiCall({
      url: selectAppRoleUrl(),
      method: "POST",
      payload: { role, applicationId: id, user_id: user?.user_id || userId || "", application_uid: uid },
    })
    const { permissions = {} } = data
    if (switchOperation) {
      setUserDetails({
        ...user,
        selectedApplication: newRole,
        permissions,
      })
      setTimeout(() => {
        const switchedUrl = getHomePage({ role, currentSubdomain: subdomain })
        broadcastEvent({ eventName: "navigateToUrl", details: { url: switchedUrl } })
        window.localStorage.removeItem("roleSwitchInProgress")
        window.location.pathname = switchedUrl
      }, 2000)
    } else {
      setUserDetails({
        selectedApplication: newRole,
        ...userDetails,
        permissions,
      })
    }
  }
  return [switchRole]
}
