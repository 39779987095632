import { useState } from "react"
import { useParams } from "react-router-dom"
import { Col, Modal, Row, Form } from "antd"
import { any, bool, func, string } from "prop-types"
import { DynamicForm } from "../../DynamicForm"
import "./styles.scss"
import { Loader } from "../../../Loader"
import { DEFAULT_MODAL_WIDTH } from "../../../../constants/common"

export const ModalTemplate = (props) => {
  const params = useParams()
  const { uid = "" } = params
  const [form] = Form.useForm()
  const {
    hideModal = () => {},
    open = false,
    onOk = () => {},
    icon = "",
    modalTitle = "",
    okText = "",
    cancelText = "",
    formDetails = {},
    patientId = uid,
    actionType = "",
    optionsData = {},
    showLoader = false,
    width = DEFAULT_MODAL_WIDTH,
  } = props
  const [openStatus, setOpenStatus] = useState(open)
  const hide = () => {
    setOpenStatus(false)
    hideModal()
  }
  const saveCallback = (inputData) => {
    onOk({ action: actionType, formData: inputData })
  }
  const onYes = () => {
    form.submit()
  }
  return (
    <Modal
      width={width}
      open={openStatus}
      centered
      onCancel={hide}
      onOk={onYes}
      okText={okText}
      cancelText={cancelText}
    >
      {showLoader && <Loader size={60} type="semiTransparentFullPage" />}

      <Row align="middle">
        {icon && (
          <Col span={3}>
            <img src={icon} alt="" className="modal-icon" />
          </Col>
        )}
        <Col span={icon ? 9 : 12}>
          <div className="modal-title">{modalTitle}</div>
          <DynamicForm
            formDetails={formDetails}
            saveCallback={saveCallback}
            formType="modalForm"
            uploadFileParams={{ patientId }}
            customForm={form}
            optionsData={optionsData}
            showFormFooter={false}
            formSectionBuilderWidth={12}
          />
        </Col>
      </Row>
    </Modal>
  )
}

ModalTemplate.defaultProps = {
  hideModal: () => {},
  open: false,
  onOk: () => {},
  icon: "",
  modalTitle: "",
  okText: "",
  cancelText: "",
  patientId: "",
  actionType: "",
  formDetails: {},
  optionsData: {},
}

ModalTemplate.propTypes = {
  hideModal: func,
  open: bool,
  onOk: func,
  icon: string,
  modalTitle: string,
  okText: string,
  cancelText: string,
  // eslint-disable-next-line react/forbid-prop-types
  patientId: any,
  // eslint-disable-next-line react/forbid-prop-types
  optionsData: any,
  actionType: string,
  // eslint-disable-next-line react/forbid-prop-types
  formDetails: any,
}
