/**
 *
 * @returns string url
 */
export const fetchCreateDoctorFormUrl = () => `/master/fetch-create-doctor-form`
/**
 *
 * @returns string url
 */
export const createDoctorUrl = () => `/master/create-doctor`
/**
 *
 * @returns string url
 */
export const createHospitalUrl = () => `/master/create-hospital`
/**
 *
 * @returns string url
 */
export const createDistributorUrl = () => `/master/create-distributor`
/**
 *
 * @returns string url
 */
export const createLabUrl = () => `/master/create-lab`
/**
 *
 * * @returns string url
 */
export const updateLabUrl = () => `/master/update-lab`
/**
 *
 * @returns string url
 */
export const editDoctorUrl = () => `/master/edit-doctor`
/**
 *
 * @returns string url
 */
export const editHospitalUrl = () => `/master/edit-hospital`
/**
 *
 * @returns string url
 */
export const editDistributorUrl = () => `/master/edit-distributor`
/**
 *
 * @returns string url
 */
export const getDoctorProfileUrl = () => `/master/get-doctor`
/**
 *
 * @returns string url
 */
export const getHospitalProfileUrl = () => `/master/get-hospital`
/**
 *
 * @returns string url
 */
export const getDistributorProfileUrl = () => `/master/get-distributor`

/**
 *
 * * @returns string url
 */
export const getLabProfileUrl = () => `/master/get-lab`

/**
 *
 * @returns string url
 */
export const getAllDoctorsUrl = () => `/master/get-all-doctors`
/**
 *
 * @returns string url
 */
export const getMasterOptionsUrl = () => `/master/fetch-master-options`
/**
 *
 * @returns string url
 */
export const getAllHospitalsUrl = () => `/master/get-all-hospitals`
/**
 *
 * @returns string url
 */
export const getAllDistributorsUrl = () => `/master/get-all-distributors`
/**
 *
 * * @returns string url
 */
export const getAllLabsUrl = () => `/master/get-all-labs`
/**
 *
 * @returns string url
 */
export const doctorConsentRetriggerUrl = () => `/master/doctor-consent-retrigger`
/**
 *
 * @returns string url
 */
export const uploadDoctorConsentUrl = () => `/master/upload-doctor-consent`
/**
 *
 * @returns string url
 */
export const exportDashboardUrl = () => `/master/export`
