import { useNavigate } from "react-router-dom"
import { Form, Input, Row, Col, Button, notification } from "antd"
import "./styles.scss"
import { useMutateData } from "../../../../utils/hooks"
import { verifyPvOrderUrl } from "../../../../api/endpoints"
import { Loader } from "../../../Loader"

export const VerifyPvOrderId = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [apiCall, { loading }] = useMutateData({ showNotification: false })
  const [toastMessage, contextHolder] = notification.useNotification()
  const goToMarkPage = async ({ complete = true }) => {
    const orderId = form.getFieldValue("order_id")
    await form.validateFields()
    if (!orderId) {
      toastMessage.error({
        key: "verifyPvOrderError",
        message: "Error",
        description: "OrderID is required",
      })
      return
    }
    const { data = {} } = await apiCall({
      url: verifyPvOrderUrl(),
      method: "POST",
      payload: { order_id: orderId },
    })
    const { status = "", id = "" } = data
    const error = !(data?.is_order_id_valid && status === "OPEN")
    let errMsg = null
    errMsg = ""
    if (!data?.is_order_id_valid) {
      errMsg = `Please enter a valid order ID to proceed`
    } else if (status !== "OPEN") {
      errMsg = `Order is already marked as ${status}`
    }
    if (error) {
      toastMessage.error({
        key: "verifyPvOrderError",
        message: "Error",
        description: errMsg,
      })
    }
    if (!error) {
      if (complete) {
        navigate({ pathname: `/phlebo/mark-pv-order-verified/${orderId}/${id}` })
      } else {
        navigate({ pathname: `/phlebo/mark-pv-order-incomplete/${orderId}/${id}` })
      }
    }
  }
  return (
    <div className="verify-pv-order-container">
      {loading && <Loader type="semiTransparentFullPage" size={60} />}
      {contextHolder}
      <div className="pv-title">Enter the 1mg order ID assigned to you to complete Physical Verification</div>
      <div className="validate-pv-order-container">
        <Form form={form} layout="vertical" className="validate-pv-order-form">
          <Row>
            <Col span={12}>
              <Form.Item name="order_id" key="order_id" label="1mg Order ID" required>
                <Input placeholder="Enter 1mg Order ID" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Button
                type="primary"
                size="large"
                className="form-btn"
                onClick={() => {
                  goToMarkPage({ complete: true })
                }}
              >
                Mark order as complete
              </Button>
            </Col>
            <Col span={12}>
              <Button
                type="default"
                size="large"
                className="form-btn"
                onClick={() => {
                  goToMarkPage({ complete: false })
                }}
              >
                Mark order as incomplete
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  )
}
