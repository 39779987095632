export const TAG_TYPES = {
  processing: "#D5FFFF",
  warning: "#FECF7F",
  success: "#E7F7E5",
  error: "error",
}

export const TEXT_TAG_TYPE_MAPPING = {
  open: "geekblue",
  approved: "green",
  rejected: "red",
  closed: "green",
  cancelled: "red",
}

export const STATUS_TEXT_MAPPING = {
  open: "Open",
  approved: "Approved",
  rejected: "Rejected",
  closed: "Closed",
  cancelled: "Cancelled",
}
