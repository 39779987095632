// Login form Validation rules
export const validationRules = {
  email: [
    {
      required: true,
      type: "email",
      message: "Enter a valid email id",
    },
  ],
  otp: [
    {
      required: true,
      message: "",
    },
    {
      pattern: /^\d{1,1}$/,
      message: "",
    },
  ],
  role: [
    {
      required: true,
      message: "Please select a role",
    },
  ],
}

export const GENERIC_ERROR_MESSAGE = "Something went wrong"
export const OTP_RESEND_MESSAGE = "You will soon receive an email with the OTP"
export const USER_NOT_EXIST_MESSAGE = "User does not exist."

export const OTP_RESEND_TIME = 30

// Login form steps enums
export const EMAIL_STEP = "EMAIL"
export const OTP_STEP = "OTP"
export const SELECT_ROLE_STEP = "SELECT_ROLE"
