import { PHLEBO_PORTAL_ICONS } from "../../../../constants/images"

export const formConfig = {
  MARK_AS_VERIFIED_FORM: {
    formTitle: "Mark this patient as verified",
    submitBtnTxt: "Mark Verified",
    action: "VERIFIED",
    afterUpdateTitle: "Patient Verified",
    iconURL: PHLEBO_PORTAL_ICONS.MARK_AS_VERIFY_SUCCESS_ICON,
  },
  MARK_AS_INCOMPLETE_FORM: {
    formTitle: "Mark this PV as incomplete",
    submitBtnTxt: "Mark Incomplete",
    action: "INCOMPLETE",
    afterUpdateTitle: "PV marked as Incomplete",
    iconURL: PHLEBO_PORTAL_ICONS.MARK_AS_INCOMPLETE_SUCCESS_ICON,
  },
}
