import React, { Suspense } from "react"
import { useSelector } from "react-redux"
import { Route, Routes, Navigate, useParams } from "react-router-dom"
import { ROUTES } from "../../constants/routes"
import { ROUTE_NOT_ALLOWED } from "../../constants/common"
import { Loader } from "../../components/Loader"
import { getCurrentSubdomain } from "../../utils/common"
import PhysicalVerificationDashboard from "../../components/DLS/PhysicalVerificationDashboard"

const {
  PHYSICAL_VERIFICATION: {
    children: { DASHBOARD },
  },
} = ROUTES

const PhysicalVerificationPage = () => {
  const params = useParams()
  const { appslug = "" } = params
  const isRoleSwitchInProgress = window.localStorage.getItem("roleSwitchInProgress") || false
  const currentSubdomain = useSelector(getCurrentSubdomain)
  if (appslug !== currentSubdomain && !isRoleSwitchInProgress) {
    return <Navigate to={ROUTE_NOT_ALLOWED} />
  }
  return (
    <Suspense fallback={<Loader type="fullPage" size={60} />}>
      <Routes>
        <Route path={DASHBOARD.path} element={<PhysicalVerificationDashboard />} />
      </Routes>
    </Suspense>
  )
}

export default PhysicalVerificationPage
