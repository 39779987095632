import React from "react"
import { Link } from "react-router-dom"
import * as AntIcons from "@ant-design/icons"
import { Menu, Dropdown, Button } from "antd"
import { MenuOutlined } from "@ant-design/icons"
import SubMenu from "antd/lib/menu/SubMenu"

const getIcon = (icon) => {
  const Icon = AntIcons[icon]
  return Icon && <Icon />
}

const ModuleMenu = ({ SIDE_MENU, dropdownProps }) => {
  const renderMenuItem = (menuItem) => {
    const { key, label, icon, children } = menuItem

    if (children && children.length > 0) {
      return (
        <SubMenu key={key} title={label} icon={icon && getIcon(icon)}>
          {children.map((child) => renderMenuItem(child))}
        </SubMenu>
      )
    }

    return (
      <Menu.Item key={key} icon={icon && getIcon(icon)}>
        <Link to={key}>{label}</Link>
      </Menu.Item>
    )
  }

  return (
    <Dropdown
      overlay={
        <Menu mode="inline" theme="light">
          {SIDE_MENU.map((menuItem) => renderMenuItem(menuItem))}
        </Menu>
      }
      {...dropdownProps}
    >
      <Button type="text">
        <MenuOutlined />
      </Button>
    </Dropdown>
  )
}

export default ModuleMenu
