import { useState, useEffect, useCallback } from "react"
import { useParams } from "react-router-dom"
import { array, bool, func, number, object, string } from "prop-types"
import { Table, Pagination, Row, Col } from "antd"
import { CalendarOutlined, ControlOutlined, FieldTimeOutlined, SearchOutlined } from "@ant-design/icons"
import Paragraph from "antd/lib/typography/Paragraph"
import { ColumnFilter } from "./columnFilter"
import "./styles.scss"
import { EMPTY_DATATABLE_ICON } from "../../../constants/images"
// import { FILE_UPLOAD_ICON } from "../../../constants/images"

export const DataTableWithPagination = (props) => {
  const {
    setOtherParams,
    otherParams,
    disableSearch = false,
    disablePagination = false,
    resetPageNo = false,
    disableSticky = false,
    type = "",
    api = () => {},
    pageSize = 10,
    customDropdownOptions = {},
    resetFieldsFlag = false,
  } = props
  const params = useParams()
  const { appslug = "" } = params
  const [tablePageSize, setTablePageSize] = useState(pageSize)
  const [tablePageNo, setTablePageNo] = useState(0)
  const fetchDataFromApi = useCallback(() => {
    if (api && tablePageSize > 0) {
      api({
        page_number: tablePageNo,
        page_size: tablePageSize,
        ...otherParams,
      })
    }
  }, [tablePageNo, tablePageSize, otherParams, appslug, type])
  const setSearchInput = (searchParam, searchValue = "") => {
    if (searchValue) {
      setTablePageNo(0)
      setOtherParams({
        ...otherParams,
        [searchParam]: searchValue,
      })
    } else {
      let formattedParams = {}
      formattedParams = { ...otherParams }
      delete formattedParams[searchParam]
      setTablePageNo(0)
      setOtherParams(formattedParams)
    }
  }
  const getColumnFilter = ({
    columnLabel,
    columnName,
    close,
    searchConfig,
    showCustomDropdown,
    showCustomTimer,
    showCustomCalendar,
  }) => (
    <ColumnFilter
      placeholder={`Search ${columnLabel}`}
      name={columnName}
      onSubmit={setSearchInput}
      initialValues={otherParams}
      close={close}
      searchConfig={searchConfig}
      type={type}
      showCustomDropdown={showCustomDropdown}
      showCustomTimer={showCustomTimer}
      showCustomCalendar={showCustomCalendar}
      customDropdownOptions={customDropdownOptions}
      resetFieldsFlag={resetFieldsFlag}
    />
  )

  const getFilterIcon = (showCustomDropdown, isColumnSearched, showCustomCalendar, showCustomTimer) => {
    let iconComponent = ""

    switch (true) {
      case showCustomDropdown:
        iconComponent = <ControlOutlined />
        break
      case showCustomCalendar:
        iconComponent = <CalendarOutlined />
        break
      case showCustomTimer:
        iconComponent = <FieldTimeOutlined />
        break
      default:
        iconComponent = <SearchOutlined className={`${isColumnSearched ? "filtered-column" : ""}`} />
    }

    return iconComponent
  }

  const formatColumns = (columnsList) => {
    if (!Array.isArray(columnsList)) {
      return []
    }
    const formattedColumns = columnsList?.map((column) => {
      const columnKey = column?.key || ""
      const { searchConfig = "" } = column
      const disableSearchForThisCoulumn = column?.disableSearch || false
      const isColumnSearched = otherParams?.[columnKey] || false
      const showCustomDropdown = column?.showCustomDropdown
      const showCustomCalendar = column?.showCustomCalendar
      const showCustomTimer = column?.showCustomTimer
      const otherDetails = {
        title: <span className={`${isColumnSearched ? "filtered-column" : ""}`}>{column?.label || ""}</span>,
        key: columnKey,
        dataIndex: columnKey,
        align: "left",
        width: column?.width || "auto",
        render: (value, record) => {
          let displayValue = ""
          displayValue = value
          if (column.render) {
            displayValue = column.render(value, record)
          } else {
            displayValue = (
              <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: "..", tooltip: true, suffix: "" }}>
                {value}
              </Paragraph>
            )
          }
          return displayValue
        },
      }
      const filterIcon = getFilterIcon(
        showCustomDropdown,
        isColumnSearched,
        showCustomCalendar,
        showCustomTimer
      )

      const searchDetails = {
        filterIcon,
        filterDropdown: ({ close }) =>
          getColumnFilter({
            columnLabel: column?.label || "",
            columnName: columnKey || "",
            close,
            searchConfig,
            showCustomDropdown,
            showCustomCalendar,
            showCustomTimer,
          }),
      }
      let columnDetails = ""
      columnDetails = {
        ...otherDetails,
      }
      if (!disableSearch && !disableSearchForThisCoulumn) {
        columnDetails = {
          ...columnDetails,
          ...searchDetails,
        }
      }
      return columnDetails
    })
    return formattedColumns
  }
  const onPageChange = (selectedPageNo) => setTablePageNo(selectedPageNo - 1)
  const onShowSizeChange = (_, selectedPageSize) => {
    setTablePageSize(selectedPageSize)
  }
  const getTotalComponent = () => {
    const recordStart = tablePageNo * tablePageSize + 1
    const recordEnd = (tablePageNo + 1) * tablePageSize
    const totalCount = props?.totalDataCount || 0
    return `${recordStart} - ${recordEnd > totalCount ? totalCount : recordEnd} of ${totalCount} items`
  }
  useEffect(() => {
    setTablePageNo(0)
  }, [resetPageNo])
  useEffect(() => {
    fetchDataFromApi()
  }, [tablePageSize, tablePageNo, otherParams, fetchDataFromApi])
  useEffect(() => {
    setOtherParams({})
    setTablePageNo(0)
  }, [appslug, type])

  const customLocale = {
    emptyText: (
      <div style={{ textAlign: "center" }}>
        <img src={EMPTY_DATATABLE_ICON} alt="No data" />
        <p>No data available</p>
      </div>
    ),
  }

  return (
    <>
      <Row className="dashboard-datatable">
        <Col span={12}>
          <Table
            locale={customLocale}
            loading={props?.loading || false}
            dataSource={props?.data || []}
            columns={formatColumns(props?.columns || [])}
            bordered={false}
            pagination={false}
            sticky={!disableSticky}
          />
        </Col>
      </Row>
      {!disablePagination && (
        <Row className="dashboard-pagination">
          <Col span={12}>
            <Pagination
              total={props?.totalDataCount || 0}
              current={tablePageNo + 1}
              hideOnSinglePage
              pageSize={tablePageSize}
              showTotal={getTotalComponent}
              showSizeChanger={false}
              onChange={onPageChange}
              onShowSizeChange={onShowSizeChange}
            />
          </Col>
        </Row>
      )}
    </>
  )
}

DataTableWithPagination.defaultProps = {
  setOtherParams: () => {},
  otherParams: {},
  disableSearch: false,
  disablePagination: false,
  resetPageNo: false,
  disableSticky: false,
  type: "",
  pageSize: 10,
  api: () => {},
  totalDataCount: 0,
  loading: false,
  data: [],
  columns: [],
}

DataTableWithPagination.propTypes = {
  setOtherParams: func,
  // eslint-disable-next-line react/forbid-prop-types
  otherParams: object,
  disableSearch: bool,
  disablePagination: bool,
  resetPageNo: bool,
  disableSticky: bool,
  type: string,
  pageSize: number,
  api: func,
  totalDataCount: number,
  loading: bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: array,
  // eslint-disable-next-line react/forbid-prop-types
  columns: array,
}
