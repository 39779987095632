import { formatDate } from "../../../../utils/common"
import { getAddress } from "./common"
import { DeliveryAddressCard } from "../DeliveryAddressCard"

export const getAddressCard = (address) => <DeliveryAddressCard address={address} />

export const careGiverSection = {
  sectionLabel: "Caregiver Details",
  editIndex: 3,
  editIndexAfterCreate: 2,
  hasSubKey: true,
  hasMultipleItems: true,
  subKey: "care_giver_details",
  buttonText: "Add Caregiver",
  buttonOperation: "add",
  buttonMaxCountCheck: 3,
  fields: [
    {
      label: "Name",
      key: "care_giver_name",
    },
    {
      label: "Phone Number",
      key: "care_giver_phone_number",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "Relationship",
      key: "relationship",
    },
  ],
}
export const personalDetailsSection = {
  sectionLabel: "Personal Details",
  editIndex: 0,
  editIndexAfterCreate: 0,
  hasSubKey: false,
  fields: [
    {
      label: "Name",
      key: "name",
    },
    {
      label: "Gender",
      key: "gender",
    },
    {
      label: "Date of Birth",
      render: (summary) => (
        <div className="patient-profile-summary-field-value">{formatDate(summary?.date_of_birth || "")}</div>
      ),
    },
    {
      label: "Phone Number",
      key: "phone_number",
    },
    {
      label: "Alternate Phone Number",
      key: "alternate_number",
    },
    {
      label: "Email",
      key: "email",
    },
    {
      label: "ID Card Type",
      key: "id_card_type",
    },
    {
      label: "ID Card Number",
      key: "id_card_number",
    },
    {
      label: "Nationality",
      key: "nationality",
    },
  ],
}

export const registrationDetailsSection = {
  sectionLabel: "Registration Details",
  editIndex: 2,
  editIndexAfterCreate: 1,
  hasSubKey: false,
  fields: [
    {
      label: "Consent Date",
      render: (summary) => (
        <div className="patient-profile-summary-field-value">
          {summary?.patient_consent_date ? formatDate(summary?.patient_consent_date || "") : "--"}
        </div>
      ),
    },
    {
      label: "Documents Received On",
      render: (summary) => (
        <div className="patient-profile-summary-field-value">
          {formatDate(summary?.docs_received_on || "")}
        </div>
      ),
    },
    {
      label: "Mode",
      key: "application_received_through",
    },
  ],
}

export const permanentAddressSection = {
  sectionLabel: "Permanent Address",
  editIndex: 1,
  editIndexAfterCreate: 3,
  hasSubKey: true,
  subKey: "permanent_address",
  buttonText: "Change Permananent Address",
  buttonOperation: "edit",
  fields: [
    {
      render: getAddress,
    },
  ],
}

export const deliveryAddressSection = {
  sectionLabel: "Delivery Address",
  editIndexAfterCreate: 4,
  editIndex: 1,
  hasSubKey: true,
  subKey: "delivery_addresses",
  hasMultipleItems: true,
  buttonText: "Add Delivery Address",
  buttonOperation: "add",
  buttonMaxCountCheck: 5,
  fields: [
    {
      render: "getAddressCard",
    },
  ],
}

export const sections = [
  personalDetailsSection,
  registrationDetailsSection,
  careGiverSection,
  permanentAddressSection,
  deliveryAddressSection,
]

export const generatePatientProfileMenuItems = ({
  tagFcEnabled = false,
  createPatientInteractionEnabled = false,
}) => {
  const menuItems = []
  if (tagFcEnabled) {
    menuItems.push({
      key: "tag_fc",
      label: "Tag Field Counsellor",
    })
  }

  if (createPatientInteractionEnabled) {
    menuItems.push({
      key: "add_patient_interaction",
      label: "Add Patient Interaction",
    })
  }

  return menuItems
}
