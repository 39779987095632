/**
 *
 * @returns string url
 */
export const getAdverseEventsDashboardUrl = () => `/adverseEvents/all`

/**
 *
 * @returns string url
 */
export const getAdverseEventsOptions = () => `/adverseEvents/getOptions`

/**
 *
 * @returns string url
 */
export const sendAdverseEventsUrl = () => `/adverseEvents/sendAe`

/**
 *
 * @returns string url
 */
export const getAePdfPreviewUrl = () => `/adverseEvents/getPreview`

/**
 *
 * @returns string url
 */
export const getAeActionFormUrl = () => `/adverseEvents/getAeActionForm`

/**
 *
 * @returns string url
 */
export const rejectAeUrl = () => `/adverseEvents/reject`

/**
 *
 * @returns string url
 */
export const sendAeUrl = () => `/adverseEvents/sendAe`
