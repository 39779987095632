/**
 *
 * @returns string url
 */
export const verifyPvOrderUrl = () => `/pv/validate-pv-order-id`
/**
 *
 * @returns string url
 */
export const fetchPatientDetailsForPv = () => `/pv/get-patient-details-by-pv-id`
/**
 *
 * @returns string url
 */
export const fetchPvFormUrl = () => `/pv/fetch-form`
/**
 *
 * @returns string url
 */
export const markPvCompleteUrl = () => `/pv/mark-pv-complete`
/**
 *
 * @returns string url
 */
export const markPvInCompleteUrl = () => `/pv/mark-pv-incomplete`
/**
 *
 * @returns string url
 */
export const sendPvOtp = () => `/pv/send-pv-otp`
/**
 *
 * @returns string url
 */
export const updatePv = () => `/pv/update-pv-status`
/**
 *
 * @returns string url
 */
export const createPvUrl = () => `/pv/create-pv`
/**
 *
 * @returns string url
 */
export const editPvUrl = () => `/pv/edit-pv`
/**
 *
 * @returns string url
 */
export const getPvDataTableUrl = () => `/pv/get-pv-table-data`
/**
 *
 * @returns string url
 */
export const getPvDropdownOptionsUrl = () => `/pv/get-pv-dropdown-options`
