import React, { useEffect, useState, useRef } from "react"
import { Form, Input } from "antd"

import "./styles.scss"
import { getRules } from "../constants"

/* eslint-disable camelcase */
export const DoctorConsentForm = ({ response, sendFormValues, onFormValidationChange }) => {
  const { title, form_section, html_section } = response
  const [form] = Form.useForm()

  const initialFormValues = {}
  form_section?.fields.forEach((field) => {
    initialFormValues[field.key] = field.value || ""
  })

  const handleFormValidation = async () => {
    try {
      await form.validateFields()
      onFormValidationChange(true)
    } catch (error) {
      onFormValidationChange(false)
    }
  }

  const [formValues, setFormValues] = useState(initialFormValues)
  const titleRef = useRef(null)
  const descriptionRef = useRef(null)
  const htmlSectionRef = useRef(null)

  useEffect(() => {
    handleFormValidation()
  }, [formValues])
  const handleFieldChange = (key, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }))
  }

  useEffect(() => {
    sendFormValues(formValues)

    const refsToUpdate = [
      { ref: titleRef, content: title },
      { ref: descriptionRef, content: form_section?.description },
      { ref: htmlSectionRef, content: html_section },
    ]

    refsToUpdate.forEach((item) => {
      const { ref, content } = item
      if (ref.current) {
        ref.current.innerHTML = ""
        const parser = new DOMParser()
        const htmlDocument = parser.parseFromString(content, "text/html")
        const elementsWithCustomClasses = htmlDocument.querySelectorAll("[data-custom-class]")
        elementsWithCustomClasses.forEach((element) => {
          const customClasses = element.getAttribute("data-custom-class")
          if (customClasses) {
            const classesToAdd = customClasses.split(" ")
            classesToAdd.forEach((className) => {
              element.classList.add(className)
            })
          }
        })
        ref.current.appendChild(htmlDocument.body)
      }
    })
  }, [formValues])

  useEffect(() => {
    sendFormValues(formValues)
  }, [])

  const renderInput = (field) => {
    let displayInputField = true

    if (field.value !== null) {
      displayInputField = false
    }
    const value = formValues[field.key] !== undefined ? formValues[field.key] : field.value

    if (!displayInputField) {
      return <span className="doctor-consent-field-styling">{field.value}</span>
    }

    return (
      <Input
        className="doctor-consent-form-input-field"
        type={field.type}
        name={field.key}
        value={value}
        placeholder={`Enter ${field.label}`}
        onChange={(e) => handleFieldChange(field.key, e.target.value)}
      />
    )
  }

  const getFieldItem = (field) => {
    const fieldName = field.key || ""
    const isMandatory = field.required === "true"
    const fieldLabel = field.label || ""
    const showValidation = isMandatory && field.value === null
    const rules = getRules(fieldLabel, showValidation)

    return (
      <Form.Item key={fieldName} name={fieldName} label={fieldLabel} required={isMandatory} rules={rules}>
        {renderInput(field)}
      </Form.Item>
    )
  }

  return (
    <div>
      <div ref={titleRef}>{title}</div>
      <Form form={form}>
        <div ref={descriptionRef}>{form_section?.description}</div>
        {form_section?.fields?.map((field) => getFieldItem(field))}
      </Form>
      <div ref={htmlSectionRef} />
    </div>
  )
}
