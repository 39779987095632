import { Tag } from "antd"
import { string } from "prop-types"

export const StatusTag = (props = {}) => {
  const { text = "", color = "", backgroundColor = "" } = props
  const styleObj = {
    border: `2px solid ${color}`,
    color: `${color}`,
    backgroundColor: `${backgroundColor}`,
    borderRadius: `2rem`,
    padding: `0.125rem 0.5rem`,
  }
  return (
    <Tag color={color} style={styleObj}>
      {text}
    </Tag>
  )
}

StatusTag.defaultProps = {
  text: "",
  color: "",
  backgroundColor: "",
}

StatusTag.propTypes = {
  text: string,
  color: string,
  backgroundColor: string,
}
