export const displayColumns = [
  {
    id: 1,
    key: "id",
    label: "ID",
    searchConfig: {
      label: "ID",
      field_type: "number",
      searchConfig: {
        step_name: "uid",
        field_type: "number",
        step_name_text: "UID",
      },
    },
  },
  {
    id: 2,
    key: "user_type",
    label: "User Type",
    searchConfig: {
      label: "User Type",
      field_type: "",
      searchConfig: {
        step_name: "user_type",
        field_type: "",
        step_name_text: "User Type",
      },
    },
  },
  {
    id: 3,
    key: "created_on_date",
    label: "Date ",
    searchConfig: {
      label: "Date ",
      field_type: "date",
      searchConfig: {
        step_name: "created_on_date",
        field_type: "date",
        step_name_text: "Date ",
      },
    },
    showCustomCalendar: true,
  },

  {
    id: 4,
    key: "to",
    label: "To",
    searchConfig: {
      label: "To",
      field_type: "email",
      searchConfig: {
        step_name: "to",
        field_type: "email",
        step_name_text: "To",
      },
    },
  },
  {
    id: 5,
    key: "bound",
    label: "Outbound / Inbound",
    searchConfig: {
      label: "OUTBOUND / INBOUND",
      field_type: "text",
      searchConfig: {
        step_name: "bound",
        field_type: "text",
        step_name_text: "OUTBOUND / INBOUND",
      },
    },
  },

  {
    id: 6,
    key: "subject",
    label: "SUBJECT",
    searchConfig: {
      label: "SUBJECT",
      field_type: "",
      searchConfig: {
        step_name: "subject",
        field_type: "",
        step_name_text: "SUBJECT",
      },
    },
  },
  {
    id: 7,
    key: "status",
    label: "Status",
    searchConfig: {
      label: "Status",
      field_type: "text",
      searchConfig: {
        step_name: "status",
        field_type: "text",
        step_name_text: "Status",
      },
    },
    disableSearch: true,
  },
]
export const displayColumnsSMS = [
  {
    id: 1,
    key: "id",
    label: "ID",
    searchConfig: {
      label: "ID",
      field_type: "number",
      searchConfig: {
        step_name: "uid",
        field_type: "number",
        step_name_text: "UID",
      },
    },
  },
  {
    id: 2,
    key: "user_type",
    label: "User Type",
    searchConfig: {
      label: "User Type",
      field_type: "",
      searchConfig: {
        step_name: "user_type",
        field_type: "",
        step_name_text: "User Type",
      },
    },
  },
  {
    id: 3,
    key: "destination_no",
    label: "Destination Number",
    searchConfig: {
      label: "Destination Number",
      field_type: "number",
      searchConfig: {
        step_name: "Destination Number",
        field_type: "number",
        step_name_text: "Destination Number",
      },
    },
  },
  {
    id: 4,
    key: "created_on_date",
    label: "Date ",
    searchConfig: {
      label: "Date ",
      field_type: "date",
      searchConfig: {
        step_name: "created_on_date",
        field_type: "date",
        step_name_text: "Date ",
      },
    },
    showCustomCalendar: true,
  },
  {
    id: 5,
    key: "text",
    label: "SMS Text",
    searchConfig: {
      label: "SMS Text",
      field_type: "text",
      searchConfig: {
        step_name: "sms",
        field_type: "text",
        step_name_text: "SMS Text",
      },
    },
    disableSearch: true,
  },
  {
    id: 6,
    key: "status",
    label: "Status",
    searchConfig: {
      label: "Status",
      field_type: "text",
      searchConfig: {
        step_name: "status",
        field_type: "text",
        step_name_text: "Status",
      },
    },
    disableSearch: true,
  },
]
export const emailDetailsDisplayColumns = [
  {
    id: 1,
    key: "id",
    label: "Id",
  },
  {
    id: 2,
    key: "subject",
    label: "Subject",
  },
  {
    id: 3,
    key: "created_on_date",
    label: "Date & Time",
  },
  {
    id: 4,
    key: "to",
    label: "Recipient Email Address",
  },
  {
    id: 5,
    key: "body",
    label: "Email Body",
  },
]
export const smsDetailsDisplayColumns = [
  {
    id: 1,
    key: "id",
    label: "Id",
  },
  {
    id: 2,
    key: "destination_no",
    label: "Destination Number",
  },
  {
    id: 3,
    key: "created_on_date",
    label: "Date",
  },
  {
    id: 4,
    key: "text",
    label: "SMS Text",
  },
]
