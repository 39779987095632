import { Tag } from "antd"
import "./addressTag.css"

export const AddressTag = ({ addressType = "" }) => (
  <Tag color="#8C8C8C" backgroundColor="#D9D9D9" className="patient-address-sub-type">
    {addressType}
  </Tag>
)

AddressTag.defaultProps = {
  addressType: "",
}

AddressTag.propTypes = {
  addressType: "string",
}
