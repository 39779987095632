export const PUBLIC_ROUTE = "public"
export const PRIVATE_ROUTE = "private"
export const GUEST_ROUTE = "guest"
export const PUBLIC_DEFAULT_ROUTE = "/login"
export const ROUTE_NOT_ALLOWED = "/403"
export const PV_PUBLIC = "pv_public"
export const PV_PRIVATE = "pv_private"

export const VIEW_CORPORATE = "view_corporate"
export const CREATE_CORPORATE = "create_corporate"
export const EDIT_CORPORATE = "edit_corporate"
export const LIST_CORPORATE = "list_corporate"
export const SIDEBAR_NAVIGATION = "sidebar_navigation"

export const ERROR_IMAGES = {
  ERROR_404: { message: "The requested page does not exist.", image: "/img/404_error.svg" },
  ERROR_500: { message: "Something went wrong", image: "/img/500_error.svg" },
  ERROR_403: { message: "You are not authorized to access this page", image: "/img/500_error.svg" },
}

export const ABORT_REQUEST_ERROR_CODE = "ERR_CANCELED"
export const ABORT_REQUEST_ERROR_RESPONSE = { response: { message: "Request Canceled" } }

export const GENERIC_MESSAGE = {
  DEFAULT_ERROR: "Something went wrong",
  DEFAULT_SUCCESS: "Saved Successfully",
  "401_ERROR": "Access Denied",
}
export const DEFAULT_MAX_FILE_UPLOAD_SIZE_IN_MB = 10
export const PFIZER_AE_FORM_PREVIEW_SECTION = "pfizer_ae_form_preview"
export const DEFAULT_MODAL_WIDTH = 600
