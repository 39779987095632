import { useEffect, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { Button, Tooltip } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import { Loader } from "../../Loader"
import { useMutateData } from "../../../utils/hooks"
import { exportDashboardUrl } from "../../../api/endpoints"
import "./styles.scss"

export const ExportButtonForDashboard = (props) => {
  const { table = "", programId = "", tooltipText = "Export" } = props
  const [csvData, setCsvData] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const csvLink = useRef()
  const [callApi] = useMutateData({ showNotification: false })
  const exportTableData = async () => {
    setShowLoader(true)
    try {
      const { data = "" } = await callApi({
        url: exportDashboardUrl(),
        method: "POST",
        payload: { table, programId },
      })
      if (!data.error) {
        setCsvData(data)
      }
      setShowLoader(false)
    } catch (apiError) {
      setShowLoader(false)
    }
  }
  const downloadCsvFile = () => {
    if (csvData !== "") {
      // csvLink.current.link.click()
      const url = window.URL.createObjectURL(new Blob([csvData]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", `${table}.csv`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
    setCsvData("")
    setShowLoader(false)
  }
  useEffect(() => {
    downloadCsvFile()
  }, [csvData])
  return (
    <>
      {showLoader && <Loader size={60} type="semiTransparentFullPage" />}
      <Tooltip title={tooltipText} placement="topLeft">
        <Button
          onClick={exportTableData}
          className="custom-secondary-btn export-btn-desktop-view"
          icon={<UploadOutlined />}
        />
      </Tooltip>
      <Button
        type="primary"
        shape="circle"
        icon={<UploadOutlined style={{ fontSize: "16px", fontWeight: "bold" }} />}
        onClick={exportTableData}
        className="export-btn-mobile-view"
      />
      <CSVLink data={csvData} filename={`${table}.csv`} ref={csvLink} target="_blank" />
    </>
  )
}
