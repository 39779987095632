export * from "./auth"
export * from "./patient"
export * from "./program"
export * from "./adverseEvents"
export * from "./master"
export * from "./doctorConsent"
export * from "./physicalVerification"
export * from "./fieldCounsellor"
export * from "./labOrders"

export const BASE_API_URL = "/api"
