import { useEffect, useState } from "react"

export const PreviewPDF = (props) => {
  const { previewData = "" } = props
  const [url, setURL] = useState("")
  useEffect(() => {
    if (previewData) {
      const pdfBlob = new Blob([previewData], { type: "application/pdf" })
      const blobUrl = window.URL.createObjectURL(pdfBlob)
      setURL(blobUrl)
    }
  }, [previewData])
  return (
    <div className="pdf-container">
      {!url && <div>Loading.....</div>}
      {url && (
        <object data={url} className="pdf-preview-object" type="application/pdf">
          Loading...
        </object>
      )}
    </div>
  )
}
