export const GENERIC_PROFILE_IMAGE =
  "https://onemg.gumlet.io/staging/62ff4c50-2694-412a-982f-b6a3b11ac624.svg"

export const PAID_ORDER_ICON = "https://onemg.gumlet.io/staging/64aa12cd-4131-471e-8cea-1ea05e53a9d7.svg"
export const FOC_ORDER_ICON = "https://onemg.gumlet.io/staging/b4575869-c82b-40c8-b332-ad96f6f2e7ff.svg"
export const AE_ICON = "https://onemg.gumlet.io/staging/863c0201-0f81-462e-a615-2fbb58972277.svg"
export const LOGIN_PAGE_IMAGE = "https://onemg.gumlet.io/staging/08384734-7b44-43a0-92f8-d4e9aaba5c1f.svg"
export const ONE_MG_BRAND_LOGO = "https://onemg.gumlet.io/staging/dafbc3c2-d537-4999-9bff-68970898c640.png"

export const APPROVE_PROGRAM_ICON = "https://onemg.gumlet.io/staging/64aa12cd-4131-471e-8cea-1ea05e53a9d7.svg"
export const FILE_UPLOAD_ICON = "https://onemg.gumlet.io/staging/d59f3b1f-4228-437a-a018-670e7eb1cee8.svg"
export const APPROVAL_PENDING_ICON =
  "https://onemg.gumlet.io/staging/eeac0313-7309-4e72-a176-441ce8e396cb.svg"
export const ACTIVE_RX_ICON = "https://onemg.gumlet.io/staging/0afbefde-8604-4eb2-bbf4-51fdf00c6468.svg"
export const INACTIVE_RX_ICON = "https://onemg.gumlet.io/staging/c6dca909-d1fb-4acc-bca0-11b5b08f5482.svg"
export const SCHEME_ICON = "https://onemg.gumlet.io/staging/8440a81b-f5f1-420b-8ece-93d2345ae5df.svg"
export const REJECT_ICON = "https://onemg.gumlet.io/staging/caeefac9-962a-4d15-8a12-66e8ea2601b5.svg"
export const APPROVE_ICON = "https://onemg.gumlet.io/staging/64aa12cd-4131-471e-8cea-1ea05e53a9d7.svg"
export const DOCUMENT_SHORTFALL_ICON =
  "https://onemg.gumlet.io/staging/cc242f23-53ce-4a58-98d7-983e45611dc5.svg"
export const PV_INCOMPLETE_ICON = "https://onemg.gumlet.io/staging/23d3cd2f-8e05-4262-9701-790a2272cb19.svg"
export const EMPTY_DATATABLE_ICON = "https://onemg.gumlet.io/staging/9281249c-dc5d-4e44-8dbd-b34b7ede8679.svg"
export const PV_CARD_ICON = "https://onemg.gumlet.io/staging/7eb55753-a395-424b-9587-27da0ed7ec3b.svg"
export const PV_VERIFIED_ICON = "https://onemg.gumlet.io/staging/e49d266d-64d5-4080-b8f1-f2cf5014dd3e.svg"
export const PHLEBO_PORTAL_ICONS = {
  MARK_AS_VERIFY_SUCCESS_ICON: "https://onemg.gumlet.io/staging/e6a0741a-65be-4409-b7fd-d654c09e55a6.svg",
  MARK_AS_INCOMPLETE_SUCCESS_ICON: REJECT_ICON,
}
