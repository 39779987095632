import { Link } from "react-router-dom"
import { PresignedUrlFileIcon } from "../PresignedUrlFileIcon"

export const formatLabDashboardColumn = (column, currentSubdomain) => {
  const { key, searchConfig = {} } = column
  const fieldType = searchConfig?.field_type || ""
  let modifiedColumn = { ...column }

  if (key === "order_id") {
    modifiedColumn = {
      ...modifiedColumn,

      render: (record, value) => {
        const pathname = `/${currentSubdomain}/patient/view-program/${value?.patient_id}/${value?.program_id}`

        return (
          <Link
            to={{
              pathname,
            }}
          >
            {record}
          </Link>
        )
      },
    }
  }
  if (fieldType === "file") {
    modifiedColumn = {
      ...modifiedColumn,
      render: (value) => value && <PresignedUrlFileIcon documentId={value} showLoader />,
    }
  }

  return modifiedColumn
}
