import { node } from "prop-types"
import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import { useMemo } from "react"
import { PublicLayout } from "./Public"
import { PrivateAgentLayout } from "./PSP"
import { GuestLayout } from "./Guest"
import { ROUTES } from "../routes/routeList"
import { getHomePage } from "../constants/routes"
import {
  PRIVATE_ROUTE,
  PUBLIC_DEFAULT_ROUTE,
  PUBLIC_ROUTE,
  GUEST_ROUTE,
  PV_PRIVATE,
} from "../constants/common"
import { Error } from "../components/Error"
import { getUser, getCurrentSubdomain, getUserRole } from "../utils/common"
import { PhleboPortalPrivateLayout } from "./PhleboPortal/Private"

/**
 *
 * @description This is Layout component which checks for user and route type and returns public or private layout
 */
export const Layout = ({ children }) => {
  const { pathname } = useLocation()
  const user = useSelector(getUser)
  const currentSubdomain = useSelector(getCurrentSubdomain)
  const role = useSelector(getUserRole)
  const privateHomepage = getHomePage({ currentSubdomain, role })
  const routeType = useMemo(() => ROUTES.find(({ name }) => pathname.indexOf(name) > -1)?.route, [pathname])
  if (pathname === "/") {
    return <Navigate to={user ? privateHomepage : PUBLIC_DEFAULT_ROUTE} />
  }

  if (routeType === PUBLIC_ROUTE) {
    return <PublicLayout>{children}</PublicLayout>
  }
  if (!user && routeType === PUBLIC_ROUTE) {
    return <Navigate to={PUBLIC_DEFAULT_ROUTE} />
  }

  if (user && routeType === PRIVATE_ROUTE) {
    return <PrivateAgentLayout>{children}</PrivateAgentLayout>
  }

  if (!user && routeType === GUEST_ROUTE) {
    return <GuestLayout>{children}</GuestLayout>
  }

  if (user && routeType === PV_PRIVATE) {
    return <PhleboPortalPrivateLayout>{children}</PhleboPortalPrivateLayout>
  }

  return <Error errorStatus={404} />
}

Layout.propTypes = {
  children: node.isRequired,
}
