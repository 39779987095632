import { DEFAULT_INPUT_MAX_LIMIT } from "../DynamicForm/FormSectionBuilder/constants"
import { cities } from "./cities"

export const allowedIdCards = [
  {
    label: "Aadhar Card",
    value: "Aadhar Card",
    pattern: /^\d{12,12}$/,
    errorMessage: "Invalid Aadhar Card Number",
  },
  {
    label: "PAN Card",
    value: "PAN Card",
    pattern: /^[A-Z]{5,5}\d{4,4}[A-Z]{1,1}$/,
    errorMessage: "Invalid PAN Card Number",
  },
  {
    label: "Passport",
    value: "Passport",
    pattern: /^[A-Za-z0-9\-,.|+/()#@*_&:;'\\ [\] ]+$/,
    errorMessage: "Invalid Passport Number",
  },
  {
    label: "Driving License",
    value: "Driving License",
    pattern: /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/,
    errorMessage: "Invalid Driving License Number",
  },
  {
    label: "Voter ID",
    value: "Voter ID",
    pattern: /^[A-Z]{3}[0-9]{7}$/,
    errorMessage: "Invalid Voter ID",
  },
]

export const relationship = [
  {
    id: "Father",
    name: "Father",
  },
  {
    id: "Mother",
    name: "Mother",
  },
  {
    id: "Son",
    name: "Son",
  },
  {
    id: "Daughter",
    name: "Daughter",
  },
  {
    id: "Brother",
    name: "Brother",
  },
  {
    id: "Sister",
    name: "Sister",
  },
  {
    id: "Other",
    name: "Other",
  },
]

export const CAREGIVER_MAX_COUNT = 3

export const getCaregiverTop = ({ caregiverIndex = 1 }) => {
  const indexTitleMapping = {
    1: "Primary Caregiver",
    2: "Secondary Caregiver",
    3: "Ternary Caregiver",
  }
  return (
    <>
      {caregiverIndex > 1 && <div className="form-divider" />}
      <div className="form-sub-section-name">{indexTitleMapping[caregiverIndex] || ""}</div>
    </>
  )
}

export const getCaregiverFields = ({ caregiverIndex = 1 }) => [
  {
    step_name: `care_giver_name_${caregiverIndex}`,
    field_type: "text",
    is_mandatory: false,
    step_name_text: "Name",
    placeholder: "Enter Caregiver Name",
    top: getCaregiverTop({ caregiverIndex }),
    dependencies: [`care_giver_phone_number_${caregiverIndex}`],
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const cgNameValue = value ? value.trim() : ""
          const depValue = getFieldValue(`care_giver_phone_number_${caregiverIndex}`) || ""
          const depValue2 = getFieldValue(`care_giver_email_${caregiverIndex}`) || ""
          const depValue3 = getFieldValue(`care_giver_relationship_${caregiverIndex}`) || ""
          const cgPN = depValue ? depValue.trim() : ""
          const cgEmail = depValue2 ? depValue2.trim() : ""
          const cgRelationship = depValue3 ? depValue3.trim() : ""
          if ((cgPN || cgEmail || cgRelationship) && !cgNameValue) {
            return Promise.reject(new Error("Caregiver Name is mandatory"))
          }
          return Promise.resolve()
        },
      }),
    ],
  },
  {
    step_name: `care_giver_phone_number_${caregiverIndex}`,
    field_type: "text",
    is_mandatory: false,
    step_name_text: "Phone Number",
    placeholder: "Enter Caregiver Number",
    dependencies: [
      `care_giver_name_${caregiverIndex}`,
      "care_giver_phone_number_1",
      "care_giver_phone_number_2",
      "care_giver_phone_number_3",
    ],
    rules: [
      ({ getFieldValue }) => ({
        validator(_, value) {
          const cgPN = value ? value.trim() : ""
          const depValue = getFieldValue(`care_giver_name_${caregiverIndex}`) || ""
          const depValue2 = getFieldValue(`care_giver_email_${caregiverIndex}`) || ""
          const depValue3 = getFieldValue(`care_giver_relationship_${caregiverIndex}`) || ""
          const cgEmail = depValue2 ? depValue2.trim() : ""
          const cgRelationship = depValue3 ? depValue3.trim() : ""
          const cgNameValue = depValue ? depValue.trim() : ""
          if (!cgPN && (cgNameValue || cgEmail || cgRelationship)) {
            return Promise.reject(new Error("Caregiver Phone Number is mandatory"))
          }
          const cgPN1 = getFieldValue(`care_giver_phone_number_1`) || ""
          const cgPN2 = getFieldValue(`care_giver_phone_number_2`) || ""
          const cgPN3 = getFieldValue(`care_giver_phone_number_3`) || ""
          if (caregiverIndex === 1 && cgPN) {
            if (cgPN2 === cgPN || cgPN3 === cgPN) {
              return Promise.reject(new Error("Same Phone Number cannot be used for multiple caregivers"))
            }
          }
          if (caregiverIndex === 2 && cgPN) {
            if (cgPN1 === cgPN || cgPN3 === cgPN) {
              return Promise.reject(new Error("Same Phone Number cannot be used for multiple caregivers"))
            }
          }
          if (caregiverIndex === 3 && cgPN) {
            if (cgPN1 === cgPN || cgPN2 === cgPN) {
              return Promise.reject(new Error("Same Phone Number cannot be used for multiple caregivers"))
            }
          }
          return Promise.resolve()
        },
      }),
    ],
  },
  {
    step_name: `care_giver_email_${caregiverIndex}`,
    field_type: "email",
    is_mandatory: false,
    step_name_text: "Email",
    dependencies: [
      `care_giver_name_${caregiverIndex}`,
      `care_giver_phone_number_${caregiverIndex}`,
      `care_giver_relationship_${caregiverIndex}`,
    ],
    placeholder: "Enter Caregiver Email",
  },
  {
    step_name: `care_giver_relationship_${caregiverIndex}`,
    field_type: "dropdown",
    is_mandatory: false,
    step_name_text: "Relationship",
    placeholder: "Select Caregiver Relationship",
    options_key: "fetch_cg_relationship",
    dependencies: [
      `care_giver_name_${caregiverIndex}`,
      `care_giver_phone_number_${caregiverIndex}`,
      `care_giver_email_${caregiverIndex}`,
    ],
  },
]

export const PATIENT_INFO_SECTION = {
  name: "patient_info",
  label: "Patient Details",
  steps: [
    {
      step_name: "name",
      field_type: "freetext",
      is_mandatory: true,
      step_name_text: "Name",
      placeholder: "Enter Name",
      regex: `^[A-Za-z. ]+$`,
      regexMessage: "Only Alphabets and . are allowed",
      maxLength: DEFAULT_INPUT_MAX_LIMIT,
    },
    {
      step_name: "gender",
      field_type: "dropdown",
      is_mandatory: true,
      step_name_text: "Gender",
      options_key: "fetch_gender",
      placeholder: "Select Gender",
    },
    {
      step_name: "date_of_birth",
      field_type: "date",
      is_mandatory: true,
      step_name_text: "Date of Birth",
      disabledDate: (d) => !d || d.isAfter(new Date()),
      placeholder: "Select Date of Birth",
    },
    {
      step_name: "phone_number",
      field_type: "text",
      is_mandatory: true,
      step_name_text: "Phone Number",
      disable_during_edit: true,
      placeholder: "Enter Phone Number",
    },
    {
      step_name: "alternate_number",
      field_type: "text",
      is_mandatory: false,
      step_name_text: "Alternate Phone Number",
      placeholder: "Enter Alternate Phone Number",
      dependencies: ["phone_number"],
      rules: [
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value) {
              return Promise.resolve()
            }
            const phoneNumber = getFieldValue("phone_number")
            // const pattern = /^\d{10,10}$/
            if (value === phoneNumber) {
              return Promise.reject(new Error("Cannot be same as phone number"))
            }
            // if (value && !pattern.test(value)) {
            //   return Promise.reject(new Error("Invalid Phone Number"))
            // }
            return Promise.resolve()
          },
        }),
      ],
    },
    {
      step_name: "email",
      field_type: "text",
      is_mandatory: false,
      step_name_text: "Email",
      placeholder: "Enter Email",
    },
    {
      step_name: "id_card_type",
      field_type: "dropdown",
      is_mandatory: false,
      step_name_text: "ID Card Type",
      options_key: "fetch_id_card_type",
      placeholder: "Select ID Card Type",
      dependencies: ["id_card_number"],
      mandatory_depends_on: "id_card_number",
    },
    {
      step_name: "id_card_number",
      field_type: "text",
      is_mandatory: false,
      step_name_text: "ID Card Number",
      placeholder: "Enter ID Card Number",
      dependencies: ["id_card_type"],
      mandatory_depends_on: "id_card_type",
      overrideDefaultRules: true,
      rules: [
        ({ getFieldValue }) => ({
          validator(_, value) {
            const idCardType = getFieldValue("id_card_type")
            if (idCardType) {
              if (!value) {
                return Promise.reject(new Error("Required field, please input ID Card Number"))
              }
              const idCardDetails = allowedIdCards.find((idCard) => idCard.value === idCardType)
              const { pattern, errorMessage = `Invalid ${idCardType} Number` } = idCardDetails
              if (!pattern.test(value)) {
                return Promise.reject(new Error(errorMessage))
              }
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    {
      step_name: "nationality",
      field_type: "dropdown",
      is_mandatory: true,
      step_name_text: "Nationality",
      options_key: "fetch_nationality",
      placeholder: "Select Nationality",
    },
  ],
}

export const ADDRESS_INFO_SECTION = {
  name: "address_info",
  label: "Address Info",
}

export const regAddrMandtoryOnProofRule = ({ getFieldValue }) => ({
  validator(_, value) {
    const isBothSameAddr = getFieldValue("isBothSameAddr") !== "No"
    const isProofNotPresent = !getFieldValue("delivery_address_proof")
    if (!isProofNotPresent && isBothSameAddr) {
      if (!value) {
        return Promise.reject(new Error("Required field since proof file is uploaded"))
      }
    }
    return Promise.resolve()
  },
})

export const PERMANENT_ADDRESS_FIELDS = [
  {
    step_name: "regd_address_line_1",
    field_type: "text",
    is_mandatory: false,
    step_name_text: "Address Line 1",
    top: <div className="form-sub-section-name">Patient&apos;s Permanent Address</div>,
    dependencies: [
      "regd_address_line_2",
      "regd_city",
      "regd_pincode",
      "regd_address_type",
      "delivery_address_proof",
      "isBothSameAddr",
    ],
    mandatory_depends_on: ["regd_address_line_2", "regd_city", "regd_pincode", "regd_address_type"],
    maxLength: 200,
    rules: [regAddrMandtoryOnProofRule],
  },
  {
    step_name: "regd_address_line_2",
    field_type: "text",
    is_mandatory: false,
    step_name_text: "Address Line 2",
    maxLength: 200,
  },
  {
    step_name: "regd_city",
    field_type: "dropdown",
    is_mandatory: false,
    step_name_text: "City",
    options_key: "fetch_city",
    dependencies: [
      "regd_address_line_1",
      "regd_address_line_2",
      "regd_pincode",
      "regd_address_type",
      "delivery_address_proof",
      "isBothSameAddr",
    ],
    mandatory_depends_on: ["regd_address_line_1", "regd_address_line_2", "regd_pincode", "regd_address_type"],
    rules: [regAddrMandtoryOnProofRule],
  },
  {
    step_name: "regd_pincode",
    field_type: "text",
    is_mandatory: false,
    step_name_text: "Pincode",
    dependencies: [
      "regd_address_line_1",
      "regd_address_line_2",
      "regd_city",
      "regd_address_type",
      "delivery_address_proof",
      "isBothSameAddr",
    ],
    mandatory_depends_on: ["regd_address_line_1", "regd_address_line_2", "regd_city", "regd_address_type"],
    rules: [regAddrMandtoryOnProofRule],
  },
  {
    step_name: "regd_address_type",
    field_type: "dropdown",
    is_mandatory: false,
    step_name_text: "Address Type",
    options_key: "fetch_address_type",
    bottom: <div className="form-divider" />,
    dependencies: [
      "regd_address_line_1",
      "regd_address_line_2",
      "regd_city",
      "regd_pincode",
      "delivery_address_proof",
      "isBothSameAddr",
    ],
    mandatory_depends_on: ["regd_address_line_1", "regd_address_line_2", "regd_city", "regd_pincode"],
    rules: [regAddrMandtoryOnProofRule],
  },
]

export const DELIVERY_ADDRESS_FIELDS = [
  {
    step_name: "delivery_address_line_1",
    field_type: "text",
    is_mandatory: false,
    step_name_text: "Address Line 1",
    maxLength: 200,
    dependencies: [
      "delivery_address_line_2",
      "delivery_city",
      "delivery_pincode",
      "delivery_address_type",
      "delivery_address_proof",
    ],
    mandatory_depends_on: [
      "delivery_address_line_2",
      "delivery_city",
      "delivery_pincode",
      "delivery_address_type",
      "delivery_address_proof",
    ],
  },
  {
    step_name: "delivery_address_line_2",
    field_type: "text",
    is_mandatory: false,
    step_name_text: "Address Line 2",
    maxLength: 200,
    dependencies: [
      "delivery_address_line_1",
      "delivery_city",
      "delivery_pincode",
      "delivery_address_type",
      "delivery_address_proof",
    ],
  },
  {
    step_name: "delivery_city",
    field_type: "dropdown",
    is_mandatory: false,
    step_name_text: "City",
    options_key: "fetch_city",
    dependencies: [
      "delivery_address_line_1",
      "delivery_address_line_2",
      "delivery_pincode",
      "delivery_address_type",
      "delivery_address_proof",
    ],
    mandatory_depends_on: [
      "delivery_address_line_1",
      "delivery_address_line_2",
      "delivery_pincode",
      "delivery_address_type",
      "delivery_address_proof",
    ],
  },
  {
    step_name: "delivery_pincode",
    field_type: "text",
    is_mandatory: false,
    step_name_text: "Pincode",
    dependencies: [
      "delivery_address_line_1",
      "delivery_address_line_2",
      "delivery_city",
      "delivery_address_type",
      "delivery_address_proof",
    ],
    mandatory_depends_on: [
      "delivery_address_line_1",
      "delivery_address_line_2",
      "delivery_city",
      "delivery_address_type",
      "delivery_address_proof",
    ],
  },
  {
    step_name: "delivery_address_type",
    field_type: "dropdown",
    is_mandatory: false,
    step_name_text: "Address Type",
    options_key: "fetch_address_type",
    dependencies: [
      "delivery_address_line_1",
      "delivery_address_line_2",
      "delivery_city",
      "delivery_pincode",
      "delivery_address_proof",
    ],
    mandatory_depends_on: [
      "delivery_address_line_1",
      "delivery_address_line_2",
      "delivery_city",
      "delivery_pincode",
      "delivery_address_proof",
    ],
  },
  {
    step_name: "delivery_address_proof",
    field_type: "file_upload",
    is_mandatory: false,
    step_name_text: "Address Proof",
    dependencies: [
      "delivery_address_line_1",
      "delivery_address_line_2",
      "delivery_city",
      "delivery_pincode",
      "delivery_address_type",
      "delivery_address_proof",
      "regd_address_line_2",
      "regd_address_line_1",
      "regd_city",
      "regd_pincode",
      "regd_address_type",
    ],
  },
]

export const infoObj = (data, section) =>
  section?.steps?.reduce((obj, step) => {
    let objWithData = {}
    objWithData = { ...obj }
    const key = step?.step_name || ""
    const value = data[key] || ""
    if (key && value) {
      objWithData[key] = value
    }
    return objWithData
  }, {})

export const getDeliveryAddressRadio = ({ onChange }) => ({
  step_name: "isBothSameAddr",
  field_type: "radio",
  step_name_text: "",
  options_key: "fetch_isBothSameAddr",
  top: <div className="form-sub-section-name">Medicine Delivery Address</div>,
  defaultValue: "Yes",
  onChange,
  dependencies: [
    "regd_address_type",
    "regd_city",
    "regd_pincode",
    "regd_address_line_1",
    "regd_address_line_2",
  ],
})

export const REGISTRATION_INFO_SECTION = {
  name: "registration_info",
  label: "Registration Info",
  steps: [
    {
      step_name: "patient_consent_date",
      field_type: "date",
      is_mandatory: false,
      step_name_text: "Consent Date",
      disabledDate: (d) => !d || d.isAfter(new Date()),
    },
    {
      step_name: "docs_received_on",
      field_type: "date",
      is_mandatory: true,
      step_name_text: "Documents Received On",
      disabledDate: (d) => !d || d.isAfter(new Date()),
    },
    {
      step_name: "application_received_through",
      field_type: "dropdown",
      is_mandatory: true,
      step_name_text: "Application Received Through",
      options_key: "fetch_application_modes",
    },
  ],
}

export const CAREGIVER_SECTION = {
  name: "caregiver_info",
  label: "Caregiver Details",
}

export const PERMANENT_ADDRESS_SECTION = {
  name: "perma_addr_section",
  label: "Permanent Address",
  steps: PERMANENT_ADDRESS_FIELDS,
}

export const DELIVERY_ADDRESS_SECTION = {
  name: "delivery_address_section",
  label: "Delivery Address",
  steps: DELIVERY_ADDRESS_FIELDS,
}

export const REVIEW_SECTION = {
  name: "review_info",
  label: "Review Info",
}

export const CREATE_PATIENT_FORM = {
  requirements: {
    sections: [
      PATIENT_INFO_SECTION,
      ADDRESS_INFO_SECTION,
      REGISTRATION_INFO_SECTION,
      CAREGIVER_SECTION,
      REVIEW_SECTION,
    ],
  },
}

export const CREATE_PATIENT_OPTIONS_DATA = {
  fetch_gender: [
    { id: "Male", name: "Male" },
    { id: "Female", name: "Female" },
  ],
  fetch_id_card_type: allowedIdCards.map((card) => ({ id: card?.value, name: card?.label })),
  fetch_nationality: [
    { id: "IND", name: "Indian" },
    { id: "YEM", name: "Yemen" },
    { id: "KEN", name: "Kenya" },
    { id: "NRU", name: "Nauru" },
    { id: "NPL", name: "Nepal" },
    { id: "BGD", name: "Bangladesh" },
    { id: "ETH", name: "Ethiopia" },
    { id: "UZB", name: "Uzbekistan" },
    { id: "IRQ", name: "Iraq" },
    { id: "IOT", name: "IOT" },
    { id: "MMR", name: "Myanmar" },
    { id: "MWI", name: "Malawi" },
    { id: "TZA", name: "Tanzania" },
    { id: "LKA", name: "Sri Lanka" },
    { id: "SOM", name: "Somalia" },
    { id: "OM", name: "Oman" },
    { id: "Others", name: "Others" },
  ],
  fetch_city: cities,
  fetch_address_type: [
    { id: "Home", name: "Home" },
    { id: "Office", name: "Office" },
    { id: "Others", name: "Others" },
  ],
  fetch_isBothSameAddr: [
    { id: "No", name: "Different Address" },
    { id: "Yes", name: "Same as Permanent Address" },
  ],
  fetch_application_modes: [
    { id: "Courier", name: "Courier" },
    { id: "Email", name: "Email" },
    { id: "DocuSign", name: "DocuSign" },
    { id: "OnCall", name: "OnCall" },
    { id: "Self Registered", name: "Self Registered" },
  ],
  fetch_cg_relationship: relationship,
}

export const getEditPatientInfoSection = () => {
  let section = {}
  section = { ...PATIENT_INFO_SECTION }
  section.steps = section.steps.filter((step) => step.step_name !== "uid")
  return section
}

export const EDIT_PATIENT_FORM = [
  PATIENT_INFO_SECTION,
  REGISTRATION_INFO_SECTION,
  CAREGIVER_SECTION,
  PERMANENT_ADDRESS_SECTION,
  DELIVERY_ADDRESS_SECTION,
]

export const getPatientInfoSectionObj = (data) => infoObj(data, PATIENT_INFO_SECTION)
export const getRegistrationInfoSectionObj = (data) => infoObj(data, REGISTRATION_INFO_SECTION)
export const getAddressSectionObj = ({ data = {}, isBothSameAddr = false }) => {
  const permanentAddress = {
    regd_address_line_1: data?.regd_address_line_1 || "",
    regd_address_line_2: data?.regd_address_line_2 || "",
    regd_city: data?.regd_city || "",
    regd_pincode: data?.regd_pincode || "",
  }
  const deliveryAddress = {
    delivery_address_line_1: isBothSameAddr
      ? permanentAddress.regd_address_line_1
      : data?.delivery_address_line_1 || "",
    delivery_address_line_2: isBothSameAddr
      ? permanentAddress.regd_address_line_2
      : data?.delivery_address_line_2 || "",
    delivery_city: isBothSameAddr ? permanentAddress.regd_city : data?.delivery_city || "",
    delivery_pincode: isBothSameAddr ? permanentAddress.regd_pincode : data?.delivery_pincode || "",
  }
  return {
    delivery_addresses: [{ ...deliveryAddress }],
    permanent_address: permanentAddress,
  }
}
export const getAddressSectionObjForCreatePatient = ({ data = {}, isBothSameAddr = false }) => {
  const permanentAddress = {
    address_line_1: data?.regd_address_line_1 || "",
    address_line_2: data?.regd_address_line_2 || "",
    city: data?.regd_city || "",
    pincode: data?.regd_pincode || "",
    sub_type: data?.regd_address_type || "",
  }
  const deliveryAddress = {
    address_line_1: isBothSameAddr ? permanentAddress.address_line_1 : data?.delivery_address_line_1 || "",
    address_line_2: isBothSameAddr ? permanentAddress.address_line_2 : data?.delivery_address_line_2 || "",
    city: isBothSameAddr ? permanentAddress.city : data?.delivery_city || "",
    pincode: isBothSameAddr ? permanentAddress.pincode : data?.delivery_pincode || "",
    sub_type: isBothSameAddr ? permanentAddress.sub_type : data?.delivery_address_type || "",
    proof_file: data?.delivery_address_proof || "",
  }
  return {
    delivery_addresses: [{ ...deliveryAddress }],
    permanent_address: permanentAddress,
  }
}

export const getCaregiverList = (cgData) => {
  let cgList = ""
  cgList = []
  for (let i = 0; i < CAREGIVER_MAX_COUNT; i += 1) {
    const cgName = cgData[`care_giver_name_${i + 1}`]
    const cgNumber = cgData[`care_giver_phone_number_${i + 1}`]
    const cgEmail = cgData[`care_giver_email_${i + 1}`]
    const cgRelationship = cgData[`care_giver_relationship_${i + 1}`]
    if (cgName && cgNumber) {
      cgList[cgList.length] = {
        care_giver_name: cgName,
        care_giver_phone_number: cgNumber,
        email: cgEmail,
        relationship: cgRelationship,
        ordering: i + 1,
      }
    }
  }
  return cgList
}

export const populateSavedCaregiverForm = (data) => {
  const caregiverList = data?.care_giver_details || []
  let caregiverListObj = ""
  caregiverListObj = {
    care_giver_name_1: "",
    care_giver_phone_number_1: "",
  }
  caregiverListObj = caregiverList.reduce((accumulator, cgObj, cgIndex) => {
    const cgName = cgObj?.care_giver_name || ""
    const cgNumber = cgObj?.care_giver_phone_number || ""
    const cgEmail = cgObj?.email || ""
    const cgRelationship = cgObj?.relationship || ""
    let nextData = {}
    nextData = {
      ...accumulator,
      [`care_giver_name_${cgIndex + 1}`]: cgName,
      [`care_giver_phone_number_${cgIndex + 1}`]: cgNumber,
      [`care_giver_email_${cgIndex + 1}`]: cgEmail,
    }
    if (cgRelationship) {
      nextData = {
        ...nextData,
        [`care_giver_relationship_${cgIndex + 1}`]: cgRelationship,
      }
    }
    return nextData
  }, caregiverListObj)
  return caregiverListObj
}
