import { useSelector } from "react-redux"
import { getApplicationLogo } from "../../utils/common"
import { Logo } from "../../assets/svg/logo"
import "./styles.scss"

export const ApplicationLogo = () => {
  const logoURL = useSelector(getApplicationLogo)
  const customLogo = <img src={logoURL} alt="Application Logo" className="application-logo" />
  return logoURL ? customLogo : <Logo />
}
