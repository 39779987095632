import { useEffect, useState } from "react"
import { Row, Col, Button, notification } from "antd"
import { CloseOutlined, FileTextOutlined } from "@ant-design/icons"
import { FILE_UPLOAD_ICON } from "../../../../constants/images"
import { getTextField } from "../FormSectionBuilder/fields"
import { Loader } from "../../../Loader"
import { DEFAULT_MAX_FILE_UPLOAD_SIZE_IN_MB } from "../../../../constants/common"

export const SheetUpload = (props) => {
  const { name = "", parentFormObj, dependencies = [] } = props
  const existingDocumentIdValue = parentFormObj.getFieldValue(name)
  const [documentId, setDocumentId] = useState(existingDocumentIdValue || "")
  const [showLoader, setShowLoader] = useState(false)
  const fileInputId = `file_${name}`
  const [toastMessage, contextHolder] = notification.useNotification()
  const hiddenTextField = getTextField({ hide: true })
  const showToastMessage = ({ message = "", key = "", error = false }) => {
    if (error) {
      toastMessage.error({
        key,
        message: "Error",
        description: message,
      })
    } else {
      toastMessage.success({
        key,
        message: "Success",
        description: message,
      })
    }
  }

  const validateFields = () => parentFormObj.validateFields([name, ...dependencies])

  useEffect(() => {
    validateFields()
  }, [documentId])

  const removeFileFromParentForm = () => {
    setDocumentId("")
    document.getElementById(fileInputId).value = ""
    parentFormObj.setFieldValue(name, "")
  }
  const setUploadedFileIdToParentForm = (file) => {
    if (file) {
      setDocumentId(file.name)
      parentFormObj.setFieldValue(name, file)
      validateFields()
    }
  }
  const handleFileUpload = async ({ file }) => {
    setShowLoader(true)

    if (file) {
      setUploadedFileIdToParentForm(file)
      setShowLoader(false)
    } else {
      removeFileFromParentForm()
      setShowLoader(false)
    }
  }

  const getUploadedFilePreview = () => (
    <div className="w-100">
      <Row align="middle" className="w-100 dynamic-form-file-preview-container">
        <Col span={1} className="text-center">
          <FileTextOutlined size={24} className="cursor-pointer" />
        </Col>
        <Col span={10} className="cursor-pointer">
          {name}
        </Col>
        <Col span={1} className="text-center">
          <Button type="text" icon={<CloseOutlined size={24} />} onClick={removeFileFromParentForm} />
        </Col>
      </Row>
    </div>
  )
  const getFileUploadButton = () => (
    <Button
      className="file-upload-btn-container"
      onClick={() => {
        document.getElementById(fileInputId).click()
      }}
    >
      <div>
        <img src={FILE_UPLOAD_ICON} alt="upload" />
      </div>
      <div className="default-text-mobile-view">Click this area or Drag and drop here to upload</div>
    </Button>
  )
  const onFileInputChange = (event) => {
    const uploadedFiles = event?.target?.files || event?.dataTransfer?.files || []
    if (uploadedFiles?.length > 1) {
      showToastMessage({
        error: true,
        key: "fileCountError",
        message: "Please upload only one file",
      })
    } else {
      const file = uploadedFiles?.[0] || ""
      const fileType = file.type
      const fileSize = file.size
      const isFileSizeMax = fileSize > DEFAULT_MAX_FILE_UPLOAD_SIZE_IN_MB * 1024 * 1024
      const isInvalidFileType = fileType !== "text/csv"
      if (!file) {
        removeFileFromParentForm()
      } else if (isInvalidFileType || isFileSizeMax) {
        showToastMessage({
          error: true,
          key: "fileTypeError",
          message: `File must be CSV, less than ${DEFAULT_MAX_FILE_UPLOAD_SIZE_IN_MB} MB`,
        })
        removeFileFromParentForm()
      } else {
        handleFileUpload({ file })
      }
    }
  }
  const onDrop = (e) => {
    e.preventDefault()
    onFileInputChange(e)
  }
  const onDragOver = (e) => {
    e.preventDefault()
  }
  return (
    <div onDrop={onDrop} onDragOver={onDragOver}>
      {contextHolder}
      {showLoader && <Loader size={60} type="semiTransparentFullPage" />}
      {hiddenTextField}
      <input
        type="file"
        id={fileInputId}
        style={{ display: "none" }}
        accept="text/csv"
        onChange={onFileInputChange}
      />
      {!documentId && getFileUploadButton()}
      {documentId && getUploadedFilePreview()}
    </div>
  )
}
