import { useEffect, useState } from "react"
import { useMutateData } from "../../../../utils/hooks"
import { Loader } from "../../../Loader"
import { getAePdfPreviewUrl } from "../../../../api/endpoints"
import { PreviewPDF } from "../../PreviewPDF"
import "./pfizerAeFormPreviewStyles.scss"

export const PfizerAeFormPreview = ({ formData = {} }) => {
  const [previewData, setPreviewData] = useState("")
  const [showLoader, setShowLoader] = useState(false)
  const [callApi] = useMutateData({ showNotification: false })
  const getPreview = async () => {
    setShowLoader(true)
    try {
      const { data = "" } = await callApi({
        url: getAePdfPreviewUrl(),
        method: "POST",
        payload: formData,
        axiosConfig: {
          responseType: "arraybuffer",
        },
      })
      if (!data.error) {
        setPreviewData(data)
      }
      setShowLoader(false)
    } catch (apiError) {
      setShowLoader(false)
    }
  }
  useEffect(() => {
    if (JSON.stringify(formData) !== "{}") {
      getPreview()
    }
  }, [formData])
  return (
    <>
      {showLoader && <Loader size={60} type="semiTransparentFullPage" />}
      <PreviewPDF previewData={previewData} />
    </>
  )
}
