import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { Form, Button, notification } from "antd"
import {
  createPatientInteractionUrl,
  fetchFcFormUrl,
  getFcFormDropdownOptionsUrl,
} from "../../../api/endpoints"
import { useMutateData } from "../../../utils/hooks"
import { Loader } from "../../Loader"
import "./styles.scss"
import { formatTime, getCurrentSubdomain } from "../../../utils/common"
import { FormSectionBuilder } from "../DynamicForm/FormSectionBuilder"

export const PatientInteractionForm = (props) => {
  const navigate = useNavigate()
  const currentSubdomain = useSelector(getCurrentSubdomain)
  const { patientId, dataObj = {}, showPatientInteractionForm = null } = props
  const [formFields, setFormFields] = useState()
  const [markForm] = Form.useForm()
  const [formData, setFormData] = useState(dataObj)
  const [callApi, { loading }] = useMutateData({ showNotification: false })
  const [optionsData, setOptionsData] = useState({})
  const [toastMessage, contextHolder] = notification.useNotification()
  const [disableActionBtn, setDisableActionBtn] = useState(false)

  const fetchOptions = ({ key = "" }) =>
    new Promise((resolve) => {
      callApi({
        url: getFcFormDropdownOptionsUrl(),
        method: "POST",
        payload: { options_key: key },
      }).then((data = {}) => {
        resolve(data)
      })
    })

  const getAllDropdownData = async (formDetails) => {
    const sections = formDetails?.sections || []
    const dropdowns = sections.reduce((dList, section) => {
      const sectionDropdowns = section?.steps?.filter((step) => step?.field_type === "dropdown") || []
      return dList.concat(sectionDropdowns)
    }, [])
    const allDropdownData = await dropdowns.map((dropdown) =>
      fetchOptions({ key: dropdown?.options_key || "" })
    )

    Promise.all(allDropdownData).then((values) => {
      let dropdownValues = ""
      dropdownValues = {}
      dropdowns.forEach((dropdown, i) => {
        const index = dropdown?.options_key
        dropdownValues[dropdown?.options_key] = values[i]?.data[index] || values[i] || []
      })
      const tempOptions = {
        ...optionsData,
        ...dropdownValues,
      }
      setOptionsData(tempOptions)
    })
  }

  const fetchFormFields = async () => {
    try {
      const { data } = await callApi({
        url: fetchFcFormUrl(),
        payload: { type: "PATIENT_INTERACTION", patient_id: patientId },
        method: "POST",
      })
      setFormFields(data)
      await getAllDropdownData(data || {})
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchFormFields()
  }, [])

  const preparePayload = (formDataObj) => {
    const processedDetails = { ...formDataObj }
    Object.keys(processedDetails).forEach((key) => {
      const value = processedDetails[key]

      if (value === undefined) {
        if (key.toLowerCase() === "ae_reported") {
          processedDetails[key] = false
        } else {
          processedDetails[key] = null
        }
      }
      if (/^-?\d*\.?\d+$/.test(value) && key !== "id") {
        processedDetails[key] = parseFloat(value)
      } else if (key.toLowerCase().includes("date")) {
        processedDetails[key] = value.format("YYYY-MM-DD")
      } else if (key.toLowerCase().includes("time")) {
        processedDetails[key] = formatTime(value)
      }
    })

    processedDetails.patient_id = +patientId

    return processedDetails
  }

  const redirectToPatientInteractionDashboard = () => {
    setTimeout(() => {
      navigate({ pathname: `/${currentSubdomain}/fc/patient-interaction-module` })
    }, 2000)
  }

  const createPatientInteraction = async (formDataObj) => {
    const payload = preparePayload(formDataObj)
    setDisableActionBtn(true)
    try {
      const response = await callApi({
        url: createPatientInteractionUrl(),
        method: "POST",
        payload,
      })
      if (response.status === 200) {
        toastMessage.success({
          key: "createPatientInteractionSuccess",
          message: "Success",
          description: response?.data?.message || "Patient interaction saved successfully",
        })
        redirectToPatientInteractionDashboard()
      } else {
        toastMessage.error({
          key: "createPatientInteractionError",
          message: "Error",
          description: response?.data?.message || "Patient interaction not saved",
        })
        setDisableActionBtn(false)
      }
    } catch (err) {
      toastMessage.error({
        key: "createPatientInteractionError",
        message: "Error",
        description: "Patient interaction not saved",
      })
      setDisableActionBtn(false)
    }
  }

  const saveFormData = (data) => {
    const formattedData = Object.keys(data).reduce((acc, dataKey) => {
      let dataVal = ""
      dataVal = data[dataKey]
      acc[dataKey] = dataVal
      if (dataVal && dataVal.length > 0) {
        acc[dataKey] = dataVal.trim()
      }
      return acc
    }, {})
    setFormData(formData)
    createPatientInteraction(formattedData)
  }
  const handleFormBackBtn = () => {
    markForm.resetFields()
    showPatientInteractionForm(false)
  }

  const getFormBody = () => (
    <div className="patient-interaction-form-page-container">
      <div className="patient-interaction-form-title">Patient Interaction Form</div>
      <div className="patient-interaction-form-container">
        {formFields?.sections?.[0] && (
          <div className="form-layout-container">
            <div className="form-layout">
              <div className="form-body">
                <Form
                  className="dynamic-form-fields-container"
                  form={markForm}
                  layout="vertical"
                  onFinish={saveFormData}
                >
                  <div className="form-fields-container">
                    <FormSectionBuilder
                      section={formFields.sections[0]}
                      fields={formFields.sections[0]?.steps}
                      optionsData={optionsData}
                      formObj={markForm}
                      uploadFileParams={{ patientId }}
                    />
                  </div>

                  <div className="form-footer-mobile">
                    <div>
                      <Button
                        onClick={() => handleFormBackBtn()}
                        className="form-previous-btn"
                        disabled={disableActionBtn}
                      >
                        Cancel
                      </Button>
                    </div>
                    <div>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="form-submit-btn"
                        disabled={disableActionBtn}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )

  return (
    <>
      {contextHolder}
      {loading && <Loader size={40} type="semiTransparentFullPage" />}
      {getFormBody()}
    </>
  )
}
