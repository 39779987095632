export const Logo = () => (
  <svg width="120" height="48" viewBox="0 0 78 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.1034 6.81541C53.1034 7.12327 53.1034 7.40745 53.1034 7.69162C53.127 7.69162 53.1507 7.69162 53.1507 7.66794C53.5524 7.12327 54.096 6.81541 54.7342 6.64964C55.4196 6.46019 56.0814 6.50756 56.7431 6.74437C57.2395 6.91014 57.6176 7.24168 57.9012 7.66794C57.9249 7.7153 57.9721 7.76267 58.0194 7.85739C58.043 7.81003 58.0667 7.76267 58.0903 7.73898C58.563 7.12327 59.1775 6.72069 59.9338 6.60228C60.6901 6.46019 61.4464 6.53124 62.1318 6.88646C63.0063 7.31272 63.5026 8.02316 63.6444 8.99409C63.6917 9.2309 63.6917 9.4914 63.6917 9.72821C63.6917 11.4806 63.6917 13.233 63.6917 15.0091C63.6917 15.0802 63.6917 15.1512 63.6917 15.2223C62.8645 15.2223 62.0373 15.2223 61.2101 15.2223C61.2101 15.1275 61.2101 15.0565 61.2101 14.9618C61.2101 13.4462 61.2101 11.9543 61.2101 10.4386C61.2101 10.2492 61.1864 10.0834 61.1628 9.89398C61.0446 9.37299 60.7137 9.01777 60.1701 8.92305C59.6738 8.852 59.2011 8.92305 58.8466 9.34931C58.6103 9.63349 58.4921 9.96502 58.4921 10.3439C58.4921 11.007 58.4921 11.6701 58.4921 12.3332C58.4921 13.2094 58.4921 14.1092 58.4921 14.9855C58.4921 15.0565 58.4921 15.1275 58.4921 15.1986C57.6412 15.1986 56.814 15.1986 55.9632 15.1986C55.9632 15.1275 55.9632 15.0565 55.9632 14.9618C55.9632 13.4462 55.9632 11.9306 55.9632 10.415C55.9632 10.1782 55.9395 9.94134 55.8686 9.72821C55.6559 9.18354 55.2778 8.94673 54.7814 8.89937C54.5924 8.87568 54.3797 8.89937 54.1906 8.94673C53.6706 9.1125 53.387 9.4914 53.2688 10.0124C53.2452 10.1308 53.2452 10.2729 53.2452 10.415C53.2452 11.9306 53.2452 13.4462 53.2452 14.9618C53.2452 15.0328 53.2452 15.1039 53.2452 15.1986C52.3943 15.1986 51.5435 15.1986 50.6927 15.1986C50.6927 12.4042 50.6927 9.6098 50.6927 6.81541C51.449 6.81541 52.2762 6.81541 53.1034 6.81541Z"
      fill="black"
    />
    <path
      d="M70.7581 7.59614C70.7581 7.33564 70.7581 7.07515 70.7581 6.81465C71.5853 6.81465 72.3889 6.81465 73.2161 6.81465C73.2161 6.86202 73.2161 6.93306 73.2161 6.98042C73.2161 9.49064 73.2161 12.0009 73.2161 14.4874C73.2161 15.4346 72.9798 16.3109 72.5071 17.1397C72.0107 17.9922 71.3017 18.5369 70.3799 18.7974C69.2927 19.1052 68.1819 19.1052 67.0947 18.7737C66.2439 18.5132 65.5585 17.9922 65.0385 17.2581C64.8258 16.9502 64.6603 16.595 64.5894 16.2398C64.5894 16.1924 64.5658 16.1688 64.5658 16.1214C64.5658 16.0977 64.5658 16.0504 64.5422 16.003C65.2985 15.7899 66.0312 15.5767 66.7875 15.3636C66.8111 15.411 66.8347 15.482 66.8347 15.5294C67.0474 16.2161 67.6619 16.7608 68.5128 16.7845C68.82 16.7845 69.1273 16.7608 69.4109 16.6661C70.049 16.4293 70.4272 15.932 70.5926 15.2926C70.6872 14.89 70.7108 14.4637 70.6872 14.0374L70.6636 14.0138H70.6399C70.2854 14.4637 69.8127 14.7005 69.2455 14.7952C68.5128 14.9137 67.7801 14.9137 67.0947 14.6295C65.8657 14.1559 65.0621 13.2796 64.7076 12.0482C64.3767 10.9115 64.4476 9.7985 65.0149 8.75652C65.4876 7.85663 66.1966 7.16987 67.1893 6.83834C67.9456 6.60152 68.7019 6.57784 69.4582 6.76729C69.9781 6.8857 70.4036 7.16987 70.7581 7.59614ZM67.1183 10.7457C67.1183 10.8405 67.1183 10.9115 67.1183 11.0062C67.1893 11.693 67.5438 12.214 68.1819 12.4745C68.4419 12.5929 68.7491 12.6166 69.0328 12.5929C69.8363 12.5455 70.4036 12.0719 70.5926 11.4088C70.6872 11.1246 70.7108 10.8168 70.6872 10.5089C70.5927 9.37223 69.5291 8.59075 68.3237 8.96965C67.5674 9.23015 67.1183 9.89322 67.1183 10.7457Z"
      fill="black"
    />
    <path
      d="M50.6448 5.65401C50.6448 4.77781 50.6448 3.9016 50.6448 3.02539C58.1607 3.02539 65.6765 3.02539 73.1923 3.02539C73.2396 3.1438 73.2396 5.2988 73.216 5.63033C73.0742 5.6777 51.0466 5.70138 50.6448 5.65401Z"
      fill="black"
    />
    <path
      d="M46.9115 18.963C46.9115 18.0868 46.9115 17.2106 46.9115 16.3344C52.5129 16.3344 58.0907 16.3344 63.6921 16.3344C63.6921 17.2106 63.6921 18.0868 63.6921 18.963C58.1143 18.963 52.5129 18.963 46.9115 18.963Z"
      fill="black"
    />
    <path
      d="M49.5344 15.1976C48.6599 15.1976 47.7855 15.1976 46.8873 15.1976C46.8873 14.795 46.8873 14.4161 46.8873 14.0135C46.8873 13.6109 46.8873 13.232 46.8873 12.8294C46.8873 12.4269 46.8873 12.048 46.8873 11.6454C46.8873 11.2428 46.8873 10.8639 46.8873 10.4613C46.8873 10.0587 46.8873 9.67984 46.8873 9.27725C46.8873 8.87467 46.8873 8.49577 46.8873 8.09319C46.8873 7.69061 46.8873 7.31171 46.8873 6.88544C46.0129 6.88544 45.162 6.88544 44.2875 6.88544C44.2875 6.29341 44.2875 5.70138 44.2875 5.08566C44.3584 5.08566 44.4293 5.08566 44.5002 5.08566C45.2566 5.0383 45.9183 4.82517 46.5092 4.32786C46.8873 4.02001 47.1237 3.61742 47.2419 3.1438C47.2419 3.12012 47.2655 3.07275 47.2655 3.04907C48.0218 3.04907 48.7781 3.04907 49.5344 3.04907C49.5344 7.07489 49.5344 11.1244 49.5344 15.1976Z"
      fill="black"
    />
    <path
      d="M21.2911 16.0268C21.1256 16.0268 20.9602 16.0268 20.8184 16.0268C19.7548 16.0268 18.6676 16.0268 17.6041 16.0268C17.4386 16.0268 17.3677 15.9795 17.3205 15.8374C16.6823 13.9902 16.0442 12.1668 15.4297 10.3196C15.4061 10.2486 15.3824 10.1776 15.3352 10.0591C15.2642 10.2723 15.1933 10.4144 15.1461 10.5801C14.5552 12.3089 13.9643 14.0613 13.3735 15.79C13.3262 15.9558 13.2553 16.0031 13.0899 16.0031C11.9554 16.0031 10.7973 16.0031 9.66283 16.0031C9.59193 16.0031 9.52102 16.0031 9.42648 16.0031C9.54466 15.719 9.6392 15.4348 9.73373 15.1506C10.8682 12.1668 12.0027 9.18294 13.1135 6.22277C13.1844 6.05701 13.2553 5.98596 13.4444 5.98596C14.697 5.98596 15.926 5.98596 17.1787 5.98596C17.3441 5.98596 17.415 6.03332 17.4859 6.19909C18.7149 9.39607 19.9675 12.6167 21.2202 15.8137C21.2675 15.8847 21.2675 15.9558 21.2911 16.0268Z"
      fill="black"
    />
    <path
      d="M40.9548 16.0268C40.8603 16.0268 40.7894 16.0268 40.7185 16.0268C39.5604 16.0268 38.4023 16.0268 37.2442 16.0268C37.0787 16.0268 37.0078 15.9795 36.9606 15.8374C36.3224 13.9902 35.6843 12.1668 35.0698 10.3196C35.0461 10.2486 35.0225 10.1776 34.9752 10.0591C34.928 10.1776 34.9043 10.2723 34.8807 10.3433C34.2662 12.1668 33.6281 13.9666 33.0136 15.79C32.9663 15.9558 32.8954 16.0268 32.7063 16.0268C31.5719 16.0268 30.461 16.0268 29.3266 16.0268C29.2557 16.0268 29.1847 16.0268 29.0902 16.0268C29.1138 15.9321 29.1375 15.8611 29.1611 15.79C30.3665 12.593 31.5955 9.39607 32.8009 6.19909C32.8718 6.03332 32.9427 5.98596 33.1317 5.98596C34.3844 5.98596 35.637 5.98596 36.8897 5.98596C37.0315 5.98596 37.1024 6.03332 37.1733 6.17541C38.4259 9.39607 39.6785 12.6404 40.9312 15.8611C40.9312 15.9084 40.9312 15.9558 40.9548 16.0268Z"
      fill="black"
    />
    <path
      d="M30.0833 6.03329C30.0833 7.00423 30.0833 7.97516 30.0833 8.96978C29.1615 8.96978 28.2634 8.96978 27.318 8.96978C27.318 11.3379 27.318 13.6824 27.318 16.0505C25.9709 16.0505 24.6473 16.0505 23.3001 16.0505C23.3001 13.706 23.3001 11.3616 23.3001 8.99346C22.3311 8.99346 21.3857 8.99346 20.4403 8.99346C20.4403 7.99884 20.4403 7.02791 20.4403 6.05698C23.631 6.03329 26.8453 6.03329 30.0833 6.03329Z"
      fill="black"
    />
    <path
      d="M0.752411 6.03329C3.96673 6.03329 7.18104 6.03329 10.419 6.03329C10.419 7.00423 10.419 7.97516 10.419 8.96978C9.49724 8.96978 8.59912 8.96978 7.65373 8.96978C7.65373 11.3379 7.65373 13.6824 7.65373 16.0505C6.30656 16.0505 4.95938 16.0505 3.61221 16.0505C3.61221 13.706 3.61221 11.3616 3.61221 8.99346C2.64318 8.99346 1.72143 8.99346 0.752411 8.99346C0.752411 7.97516 0.752411 7.00423 0.752411 6.03329Z"
      fill="black"
    />
  </svg>
)
