import { Tag } from "antd"
import { TEXT_TAG_TYPE_MAPPING, STATUS_TEXT_MAPPING } from "./constants"
import "./styles.scss"

export const StatusTag = (props = {}) => {
  const { text = "" } = props
  const tagType = TEXT_TAG_TYPE_MAPPING[text.toLowerCase()]
  const statusText = STATUS_TEXT_MAPPING[text.toLowerCase()]
  return (
    <Tag color={tagType} className={`order-status-tag pds-${tagType}`}>
      {statusText}
    </Tag>
  )
}
