export const validationRules = [
  {
    required: true,
    message: "",
  },
  {
    pattern: /^\d{1,1}$/,
    message: "",
  },
]
