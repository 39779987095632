import { Steps } from "antd"
import { array, bool, number } from "prop-types"
import "./styles.scss"

export const FormStepsHeader = (props = {}) => {
  const { sections = [], currentSection = 0, showTitle = false, showStepNumber = false } = props
  const getTitle = (section, sectionIndex) => {
    let title = null
    title = ""
    if (showTitle) {
      if (showStepNumber) {
        title = `Step ${sectionIndex + 1}`
      } else {
        title = section?.label || ""
      }
    }
    return title
  }
  const getSection = (section, sectionIndex) => ({
    title: getTitle(section, sectionIndex),
  })
  const getSteps = () => sections.map((section, sectionIndex) => getSection(section, sectionIndex))
  return (
    sections.length > 1 && (
      <Steps
        className="dynamic-form-steps"
        current={currentSection}
        labelPlacement="vertical"
        progressDot
        items={getSteps()}
      />
    )
  )
}

FormStepsHeader.defaultProps = {
  sections: [],
  currentSection: 0,
  showTitle: false,
  showStepNumber: false,
}

FormStepsHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  sections: array,
  currentSection: number,
  showTitle: bool,
  showStepNumber: bool,
}
