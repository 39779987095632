import { StatusTag } from "../../AdverseEventsDashboard/utils/statusTag"

const STATUS_MAPPINGS = {
  OPEN: { color: "#009DA5", backgroundColor: "#D1F8FA" },
  VERIFIED: { color: "#208376", backgroundColor: "#E7F7E5" },
  INCOMPLETE: { color: "#8C8C8C", backgroundColor: "#D9D9D9" },
}

export const pvActions = [
  {
    key: "edit",
    label: "Edit",
    marker: "EDIT",
  },

  {
    key: "mark_verified",
    label: "Mark Verified",
    marker: "VERIFIED",
  },

  {
    key: "mark_incomplete",
    label: "Mark Incomplete",
    marker: "INCOMPLETE",
  },
]

export const actionsArray = {
  mark_verified: "VERIFIED",
  mark_incomplete: "INCOMPLETE",
}

export const fieldsToBeDisplayedInEditMode = [
  "fulfiller",
  "order_id",
  "scheduled_on_date",
  "scheduled_on_time",
  "pv_attempt",
]
export const pvDateFields = ["scheduled_on_date"]
export const pvTimeFields = ["scheduled_on_time"]

export const formatPvDashboardColumn = (column) => {
  const { key } = column
  let modifiedColumn = { ...column }

  if (key === "status") {
    modifiedColumn = {
      ...modifiedColumn,

      render: (value) => {
        const statusTagProps = STATUS_MAPPINGS[value.toUpperCase()] || {}
        return (
          <StatusTag
            color={statusTagProps.color}
            text={value}
            backgroundColor={statusTagProps.backgroundColor}
          />
        )
      },
    }
  }
  return modifiedColumn
}
