import { useState } from "react"
import { Button, Col, Row } from "antd"
import { CloseOutlined } from "@ant-design/icons"
import "./styles.scss"

export const InformationSection = (props) => {
  const { removable = true, text = "" } = props
  const textWidth = removable ? 10 : 12
  const iconWidth = 12 - textWidth
  const [show, setShow] = useState(true)
  const hideSection = () => {
    setShow(false)
  }
  const section = () => (
    <Row className="w-100 form-information-section">
      <Col span={textWidth}>{text}</Col>
      <Col span={iconWidth} className="text-right">
        <Button type="text" icon={<CloseOutlined />} onClick={hideSection} />
      </Col>
    </Row>
  )
  return show && section()
}
