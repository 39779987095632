import React, { Suspense } from "react"
import { useSelector } from "react-redux"
import { Route, Routes, Navigate, useParams } from "react-router-dom"
import { ROUTES } from "../../constants/routes"
import { ROUTE_NOT_ALLOWED } from "../../constants/common"
import { Loader } from "../../components/Loader"
import { getCurrentSubdomain, isLabTableToBeShown } from "../../utils/common"
import { LabOrdersDashboard } from "../../components/DLS/LabOrdersDashboard"

const {
  LAB_ORDERS: {
    children: { DASHBOARD },
  },
} = ROUTES

export const LabOrdersPage = () => {
  const params = useParams()
  const { appslug = "" } = params
  const isRoleSwitchInProgress = window.localStorage.getItem("roleSwitchInProgress") || false
  const currentSubdomain = useSelector(getCurrentSubdomain)
  const accessLabOrdersDashboard = useSelector(isLabTableToBeShown)
  if (appslug !== currentSubdomain && !isRoleSwitchInProgress && accessLabOrdersDashboard) {
    return <Navigate to={ROUTE_NOT_ALLOWED} />
  }

  return (
    <Suspense fallback={<Loader type="fullPage" size={60} />}>
      <Routes>
        <Route path={DASHBOARD.path} element={<LabOrdersDashboard />} />
      </Routes>
    </Suspense>
  )
}
