export const COUNTDOWN_DURATION_SECONDS = 30
export const DOCTOR_CONSENT_URL_REGEX_PATTERN = /doctor-consent\/(.+)/
export const getRules = (fieldName, showValidation) => {
  const rules = []
  if (showValidation) {
    rules.push({
      required: true,
      message: `Mandatory field, please enter ${fieldName}`,
    })
  }

  return rules
}
