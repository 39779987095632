/**
 *
 * @returns string url
 */
export const getLabDataUrl = () => `/labOrders/fetch-lab-data`
/**
 *
 * @returns string url
 */
export const getPatientLabOrderDataUrl = () => `/labOrders/fetch-patient-lab-order-data`
