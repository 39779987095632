/**
 *
 * @returns string url
 */
export const createTokenEmailUrl = () => `/auth/create_token_email`

/**
 *
 * @returns string url
 */
export const verifyTokenEmailUrl = () => `/auth/verify_token_email`

/**
 *
 * @returns string url
 */
export const selectAppRoleUrl = () => `/auth/selectAppRole`

/**
 *
 * @returns string url
 */
export const logoutUrl = () => `/auth/logout`
