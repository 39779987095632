/**
 *
 * @returns string url
 */
export const getDocumentTypes = () => `/ocr/document-types`
/**
 *
 * @returns string url
 */
export const getOcrForm = () => `/ocr/fetch-ocr-form`
/**
 *
 * @returns string url
 */
export const uploadOcrFile = () => `/ocr/upload-ocr-file`
/**
 *
 * @returns string url
 */
export const submitOCRForm = () => `/ocr/submit-ocr-form`
