import { Link } from "react-router-dom"

export const formatCommunicationDashboardColumn = (column, currentSubdomain, type) => {
  const { key } = column
  let modifiedColumn = { ...column }

  if (key === "id") {
    modifiedColumn = {
      ...modifiedColumn,

      render: (record, value) => {
        const state = {
          id: record,
          dataObj: value,
        }
        const pathname = `/${currentSubdomain}/communication/${type.toLowerCase()}-details-page/${record}`

        return (
          <Link
            to={{
              pathname,
            }}
            state={state}
          >
            {record}
          </Link>
        )
      },
    }
  }

  return modifiedColumn
}
