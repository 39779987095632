import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Col, Row, Form, notification } from "antd"
import { PvPatientDetails } from "../PvPatientDetails"
import { useMutateData } from "../../../../utils/hooks"
import { Loader } from "../../../Loader"
import { fetchPvFormUrl, updatePv, sendPvOtp } from "../../../../api/endpoints"
import { DynamicForm } from "../../DynamicForm"
import { formConfig } from "./constants"
import "./styles.scss"
import { OTP } from "../../OTP"

export const MarkPvVerified = ({ type }) => {
  const params = useParams()
  const [markForm] = Form.useForm()
  const navigate = useNavigate()
  const [formDetails, setFormDetails] = useState({})
  const [stepsList, setStepsList] = useState({})
  const [pvStatus, setPvStatus] = useState("")
  const [patientId, setPatientId] = useState("")
  const [isSubmitBtnEnabled, enableSubmitBtn] = useState(false)
  const [toastMessage, contextHolder] = notification.useNotification()
  const updatePatientId = (pId) => {
    setPatientId(pId)
  }
  const { id = "", pvid = "" } = params
  const [apiCall, { loading }] = useMutateData({ showNotification: false })
  const fConfig = formConfig[type] || {}
  const fetchFormDetails = async () => {
    const { data = {} } = await apiCall({
      url: fetchPvFormUrl(),
      payload: { type, patientId },
      method: "POST",
    })
    let formFields = {}
    formFields = {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    }
    const sList = data?.sections?.map((section) => section?.steps || [])?.flat() || []
    const stepsNames = sList?.map((step) => step?.step_name || "")?.filter((stepName) => stepName) || []
    stepsNames.map((step) => {
      formFields[step] = ""
      return true
    })
    setStepsList(formFields)
    setFormDetails(data)
  }
  const sendOTP = async () => {
    const { data = {} } = await apiCall({
      url: sendPvOtp(),
      method: "POST",
      payload: { patientId, id: pvid },
    })
    const { message = "", error = false } = data
    if (!error) {
      toastMessage.success({
        key: "sendOTPsuccess",
        message: "Success",
        description: message,
      })
    }
  }
  useEffect(() => {
    if (id && type && patientId) {
      fetchFormDetails()
    }
  }, [id, type, patientId])
  const handleOtp = () => (
    <div className="pv-otp-container">
      <Form form={markForm}>
        <OTP resendOtpContainerWidth={6} resendOtp={sendOTP} />
      </Form>
    </div>
  )
  const callUpdateApi = async (inputData = {}) => {
    const { data = {} } = await apiCall({
      url: updatePv(),
      method: "POST",
      payload: inputData,
    })
    const { message = "", error = false } = data
    if (!error) {
      if (message) {
        toastMessage.success({
          key: "updatePvSuccess",
          message: "Success",
          description: message,
        })
      }
      setPvStatus(fConfig.action)
    }
  }
  const update = (formData = {}) => {
    const inputArr = Object.values(formData)
    const inputData = inputArr[0] || {}
    const { otp1 = "", otp2 = "", otp3 = "", otp4 = "", otp5 = "", otp6 = "" } = inputData
    const otp = `${otp1}${otp2}${otp3}${otp4}${otp5}${otp6}`
    let apiRequestData = {}
    if (otp) {
      apiRequestData = { otp }
    }
    apiRequestData = {
      ...apiRequestData,
      id: pvid,
      type: fConfig.action || "",
      ...inputData,
    }
    delete apiRequestData.otp1
    delete apiRequestData.otp2
    delete apiRequestData.otp3
    delete apiRequestData.otp4
    delete apiRequestData.otp5
    delete apiRequestData.otp6
    callUpdateApi(apiRequestData)
  }
  const getUpdatedStatus = () => (
    <div>
      <div className="pv-title">{fConfig.afterUpdateTitle}</div>
      <div className="update-pv-status-success-icon-container w-100">
        <img src={fConfig.iconURL} alt="" className="w-100" />
      </div>
      <Row className="pv-after-update-form-btn-container">
        <Col span={12}>
          <Button
            type="default"
            size="large"
            onClick={() => {
              navigate({ pathname: "/" })
            }}
          >
            Back to PV Home
          </Button>
        </Col>
      </Row>
    </div>
  )
  const trackFormChanges = () => {
    const formValues = markForm.getFieldsValue(true)
    const combinedObj = {
      ...stepsList,
      ...formValues,
    }
    const combinedValues = Object.values(combinedObj)
    const isValid = combinedValues.every((val) => val && val !== "")
    enableSubmitBtn(isValid)
  }
  const getFormBody = () => (
    <>
      <div className="pv-title">{fConfig?.formTitle || ""}</div>
      <div className="mark-pv-form">
        {type === "MARK_AS_VERIFIED_FORM" && handleOtp()}
        <DynamicForm
          formDetails={formDetails}
          saveCallback={update}
          optionsData={{
            radio_options: [
              { id: "Yes", name: "Yes" },
              { id: "No", name: "No" },
            ],
          }}
          formType="markPvVerified"
          showFormFooter={false}
          formSectionBuilderWidth={12}
          customForm={markForm}
          trackFormChanges={trackFormChanges}
        />
      </div>
      <Row className="pv-update-form-btn-container">
        <Col span={12}>
          <Button
            type="primary"
            size="large"
            disabled={type === "MARK_AS_INCOMPLETE_FORM" ? false : !isSubmitBtnEnabled}
            onClick={() => {
              markForm.submit()
            }}
          >
            {fConfig?.submitBtnTxt || "Submit"}
          </Button>
        </Col>
      </Row>
    </>
  )
  const showForm = pvStatus === "" || pvStatus === "Open"
  return (
    <>
      {loading && <Loader type="semiTransparentFullPage" size={60} />}
      {contextHolder}
      <PvPatientDetails orderId={id} type={type} updatePatientId={updatePatientId} pvid={pvid} />
      {showForm && getFormBody()}
      {!showForm && getUpdatedStatus()}
    </>
  )
}
