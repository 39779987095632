import { useState } from "react"
import { Avatar, Button, Dropdown, Menu, Row, Col } from "antd"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { ArrowLeftOutlined, CaretDownFilled } from "@ant-design/icons"
import { GENERIC_PROFILE_IMAGE } from "../../constants/images"
import {
  getUserName,
  getCurrentApplicationRoleLabel,
  getAllApplicationRoles,
  getRoleLabel,
} from "../../utils/common"
import { useRoleSwitch } from "../../utils/hooks"
import { Loader } from "../Loader"
import "./styles.scss"

export const ProfileMenu = ({ showOnlySignOut = false }) => {
  const [showRolesList, setShowRolesList] = useState(false)
  const [switchRole] = useRoleSwitch()
  const openRolesList = () => setShowRolesList(true)
  const hideRolesList = () => setShowRolesList(false)
  const currentRoleAppLabel = useSelector(getCurrentApplicationRoleLabel)
  const userName = useSelector(getUserName)
  const allRoles = useSelector(getAllApplicationRoles)
  const [showLoader, setShowLoader] = useState(false)
  const navigate = useNavigate()
  const profileSection = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!showOnlySignOut && (
        <Row justify="center">
          <Col span={6} className="text-center">
            <Avatar src={GENERIC_PROFILE_IMAGE} size={80} />
            <div className="profile-menu-name">{userName}</div>
            <div className="profile-menu-user-role">{currentRoleAppLabel}</div>
            {allRoles.length > 1 && (
              <Button className="secondary-action-btn profile-menu-btn" onClick={openRolesList}>
                Switch Role
              </Button>
            )}
          </Col>
        </Row>
      )}
    </>
  )
  const getRolesMenu = () =>
    allRoles.map((roleAppObj, index) => {
      const label = getRoleLabel(roleAppObj)
      return label !== currentRoleAppLabel
        ? {
            key: index,
            label,
            disabled: label === currentRoleAppLabel,
          }
        : null
    })
  const onRoleClick = async ({ key }) => {
    setShowLoader(true)
    await switchRole({ key })
  }
  const rolesList = (
    <div className="h-100">
      <Row gutter={[24, 24]} align="middle">
        <Col span={2}>
          <Button className="profile-menu-back-btn" type="text" onClick={hideRolesList}>
            <ArrowLeftOutlined />
          </Button>
        </Col>
        <Col span={10} className="profile-menu-switch-role-heading">
          Choose Role
        </Col>
      </Row>
      <Menu onClick={onRoleClick} items={getRolesMenu()} className="profile-menu-roles-list h-100" />
    </div>
  )
  const getDropdownContent = (
    <div className="profile-menu-container h-auto">
      {showRolesList && rolesList}
      {!showRolesList && profileSection}
    </div>
  )
  const moveToLogout = () => {
    navigate({ pathname: "/logout" })
  }
  const getPhleboPortalDropdownContent = (
    <Menu className="mobileProfileMenu" items={[{ key: "logout", label: "Logout" }]} onClick={moveToLogout} />
  )
  return (
    <div>
      {showLoader && <Loader size={60} type="opaqueFullPage" />}
      <Dropdown
        onOpenChange={() => {
          setTimeout(hideRolesList, 1000)
        }}
        overlay={showOnlySignOut ? getPhleboPortalDropdownContent : getDropdownContent}
        placement={showOnlySignOut ? "bottomLeft" : "bottom"}
        arrow
      >
        <Avatar src={GENERIC_PROFILE_IMAGE} size={32} />
      </Dropdown>
      {!showOnlySignOut && <CaretDownFilled />}
    </div>
  )
}
