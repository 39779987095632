/**
 *
 * @returns string url
 */
export const fetchFormDataUrl = () => `/doctorConsent/fetchFormData`
/**
 *
 * @returns string url
 */
export const sendOTPUrl = () => `/doctorConsent/sendOTP`
/**
 *
 * @returns string url
 */
export const verifyOTPUrl = () => `/doctorConsent/verifyOTPandSubmitData`
