import { Menu } from "antd"
import * as AntIcons from "@ant-design/icons"
import { Link, useLocation } from "react-router-dom"
import "./styles.scss"
import { useMemo } from "react"
import { any, arrayOf } from "prop-types"

/**
 *
 * @description SideBar component render's sidebar navigation according to given menu option
 */
export const SideBar = ({ data }) => {
  const location = useLocation()

  const parsedSideMenu = useMemo(() => {
    if (!Array.isArray(data)) return []
    const getMenuItem = ({ key, label, icon = "", children }) => {
      let menu = {}
      const Icon = icon && AntIcons[icon]
      if (Array.isArray(children)) {
        menu.children = children.map(getMenuItem)
        menu.label = label
      } else {
        menu.label = <Link to={key}>{label}</Link>
      }
      menu = {
        ...menu,
        key,
        icon: icon && Icon ? <Icon /> : "",
      }
      return menu
    }
    return data.map(getMenuItem)
  }, [data])

  return (
    <Menu
      mode="inline"
      theme="light"
      defaultOpenKeys={[`/${location.pathname.split("/")[1]}`]}
      selectedKeys={[location.pathname]}
      items={parsedSideMenu}
      className="mainLeftMenu"
    />
  )
}

SideBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: arrayOf(any).isRequired,
}
