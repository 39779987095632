import { CloudDownloadOutlined } from "@ant-design/icons"
import { PresignedUrlFileIcon } from "../../PresignedUrlFileIcon"
import { AddressTag } from "./addressTag"

export const getAddress = (address) => (
  <div className="patient-profile-summary-field-value">
    {address?.proof_file && (
      <div className="patient-address-proof-download">
        <PresignedUrlFileIcon showLoader documentId={address?.proof_file} icon={<CloudDownloadOutlined />} />
      </div>
    )}
    <div>{address?.sub_type && <AddressTag addressType={address?.sub_type || ""} />}</div>
    <div>
      {address?.address_line_1 || address?.regd_address_line_1 || address?.delivery_address_line_1 || ""}
    </div>
    <div>
      {address?.address_line_2 || address?.regd_address_line_2 || address?.delivery_address_line_2 || ""}
    </div>
    <div>
      {address?.city || address?.regd_city || address?.delivery_city || ""} -
      {address?.pincode || address?.regd_pincode || address?.delivery_pincode || ""}
    </div>
  </div>
)
