import { useSelector } from "react-redux"
import { Layout, Row, Col } from "antd"
import { getCurrentApplicationRoleLabel, getUserName, getUserRole } from "../../../utils/common"
import { ProfileMenu } from "../../../components/ProfileMenu"
import "./styles.scss"

const { Header, Content } = Layout
export const PhleboPortalPrivateLayout = ({ children }) => {
  const userRole = useSelector(getUserRole)
  const userRoleLabel = useSelector(getCurrentApplicationRoleLabel)
  const userName = useSelector(getUserName)
  return (
    <Layout className="phleboLayout">
      <Header userRoleLabel={userRoleLabel}>
        <Row align="middle" className="h-100">
          <Col span={2} />
          <Col span={8} className="phlebo-portal-header-text">
            Physical Verification
          </Col>
          <Col span={2} className="text-right">
            <ProfileMenu showOnlySignOut />
          </Col>
        </Row>
      </Header>
      <Content className="phlebo-content-holder" userRole={userRole} userName={userName}>
        {children}
      </Content>
    </Layout>
  )
}
