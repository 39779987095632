import React, { Suspense } from "react"
import { useSelector } from "react-redux"
import { Navigate, Route, Routes } from "react-router-dom"
import { ROUTES } from "../../constants/routes"
import { Loader } from "../../components/Loader"
import { VerifyPvOrderId } from "../../components/DLS/PhelboPortal/VerifyPvOrderId"
import { MarkPvVerified } from "../../components/DLS/PhelboPortal/MarkPvVerified"
import { hasAccessToPhleboPortal } from "../../utils/common"
import { ROUTE_NOT_ALLOWED } from "../../constants/common"

const {
  PHLEBO_PORTAL: {
    children: { VERIFY_PV_ORDER_ID, MARK_PV_VERIFIED, MARK_PV_INCOMPLETE },
  },
} = ROUTES
export const PhleboPage = () => {
  const hasAccess = useSelector(hasAccessToPhleboPortal)
  if (!hasAccess) {
    return <Navigate to={ROUTE_NOT_ALLOWED} />
  }
  return (
    <Suspense fallback={<Loader type="fullPage" size={60} />}>
      <Routes>
        <Route path={VERIFY_PV_ORDER_ID.path} element={<VerifyPvOrderId />} />
        <Route path={MARK_PV_VERIFIED.path} element={<MarkPvVerified type="MARK_AS_VERIFIED_FORM" />} />
        <Route path={MARK_PV_INCOMPLETE.path} element={<MarkPvVerified type="MARK_AS_INCOMPLETE_FORM" />} />
      </Routes>
    </Suspense>
  )
}
