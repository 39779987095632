/**
 *
 * @returns string url
 */
export const createPatientUrl = () => `/patient/create`
/**
 *
 * @returns string url
 */
export const getPatientDashboardUrl = () => `/patient/all`
/**
 *
 * @returns string url
 */
export const getPatientByUidUrl = () => `/patient/get`
/**
 *
 * @returns string url
 */
export const editPatientByUidUrl = () => `/patient/edit`
/**
 *
 * @returns string url
 */
export const uploadPatientFileUrl = () => `/patient/upload`
/**
 *
 * @returns string url
 */
export const markPatientAsActiveUrl = () => `/patient/markPatientAsActive`
/**
 *
 * @returns string url
 */
export const markPatientAsInActiveUrl = () => `/patient/markPatientAsInactive`
/**
 *
 * @returns string url
 */
export const getPatientForm = () => `/patient/getPatientForm`
/**
 *
 * @returns string url
 */
export const fetchPatientDropdownOptions = () => `/patient/fetchPatientDropdownOptions`
/**
 *
 * @returns string url
 */
export const fetchEligibleProgramsUrl = () => `/patient/fetchEligiblePrograms`
/**
 *
 * @returns string url
 */
export const fetchAdverseEventFieldsUrl = () => `/patient/fetchAdverseEventFields`
/**
 *
 * @returns string url
 */
export const createAdverseEventUrl = () => `/patient/createAdverseEvent`
/**
 *
 * @returns string url
 */
export const approveAdverseEventUrl = () => `/patient/approveAdverseEvent`
/**
 *
 * @returns string url
 */
export const closeAdverseEventUrl = () => `/patient/closeAdverseEvent`
/**
 *
 * @returns string url
 */
export const editAdverseEventUrl = () => `/patient/editAdverseEvent`
/**
 *
 * @returns string url
 */
export const labOrderFormUrl = () => `/patient/labOrderForm`
/**
 *
 * @returns string url
 */
export const scheduleSampleUrlApi = () => `/patient/scheduleSample`
