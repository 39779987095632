export * from "./common"
export * from "./cookie"
export * from "./broadcast"
export const getDropdowns = (requirements) => {
  let steps = ""
  steps = []
  steps = requirements?.sections?.map((section) => {
    const hasSubSection = section?.sub_sections?.length > 0 || false
    let subSteps = ""
    subSteps = []
    if (hasSubSection) {
      const subSections = section?.sub_sections || []
      subSteps =
        subSections
          ?.map((subSection) => {
            let subSectionSteps = ""
            subSectionSteps = subSection?.steps?.filter((step) => step?.options_key) || []
            if (subSectionSteps.length === 0) {
              if (subSection?.dynamic_rx_details_list) {
                subSectionSteps =
                  subSection?.dynamic_rx_details_list
                    ?.map((dynamicListItem) => dynamicListItem?.steps || [])
                    ?.flat(1)
                    ?.filter((step) => step?.options_key) || []
              }
            }
            return subSectionSteps
          })
          ?.flat(1) || []
    } else {
      subSteps = section?.steps?.filter((step) => step?.options_key) || []
    }
    return subSteps
  })
  steps = steps?.flat(1)?.map((step) => step?.options_key || "")
  if (requirements?.field_sets) {
    const fSetKeys =
      Object.values(requirements.field_sets)
        .flat(1)
        ?.filter((step) => step?.options_key)
        ?.map((step) => step?.options_key) || []
    steps = steps.concat(fSetKeys)
  }
  steps = Array.from(new Set(steps))
  return steps
}
