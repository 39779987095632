import { useState, useEffect } from "react"
import { Col, Row, Button, Modal, Form, notification } from "antd"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { fetchFcFormUrl, getFcFormDropdownOptionsUrl, submitIndividualFcTagUrl } from "../../../api/endpoints"
import { Loader } from "../../Loader"
import { useMutateData } from "../../../utils/hooks"
import "./styles.scss"
import { FormSectionBuilder } from "../DynamicForm/FormSectionBuilder"
import { getCurrentSubdomain, getUserRole } from "../../../utils/common"

const fieldsToBeDisplayedInEditMode = ["tagged_fc_id"]

export const FCModalContainer = (props) => {
  const { hideParentModal = null, patientId = null, fetchProfile, dataObj = null } = props
  const [markForm] = Form.useForm()
  const userRole = useSelector(getUserRole)
  const currentSubdomain = useSelector(getCurrentSubdomain)
  const [formFields, setformFields] = useState({})
  const [apiCall, { loading }] = useMutateData({ showNotification: false })
  const [optionsData, setOptionsData] = useState({})
  const [toastMessage, contextHolder] = notification.useNotification()
  const [editData, setEditData] = useState({})
  const navigate = useNavigate()

  const processData = () => {
    const payload = {
      field_counsellor: {},
    }

    fieldsToBeDisplayedInEditMode.forEach((key) => {
      if (dataObj && dataObj[key] !== undefined && dataObj[key] !== "") {
        payload.field_counsellor[key] = dataObj[key]
      }
    })

    return payload
  }
  const [openStatus, setOpenStatus] = useState(true)

  const hide = () => {
    setOpenStatus(false)
    hideParentModal()
  }

  const fetchOptions = ({ key = "" }) =>
    new Promise((resolve) => {
      apiCall({
        url: getFcFormDropdownOptionsUrl(),
        method: "POST",
        payload: { options_key: key },
      }).then((data = {}) => {
        resolve(data)
      })
    })

  const getAllDropdownData = async (formDetails) => {
    const sections = formDetails?.sections || []
    const dropdowns = sections.reduce((dList, section) => {
      const sectionDropdowns = section?.steps?.filter((step) => step?.field_type === "dropdown") || []
      return dList.concat(sectionDropdowns)
    }, [])
    const allDropdownData = await dropdowns.map((dropdown) =>
      fetchOptions({ key: dropdown?.options_key || "" })
    )

    Promise.all(allDropdownData).then((values) => {
      let dropdownValues = ""
      dropdownValues = {}
      dropdowns.forEach((dropdown, i) => {
        const index = dropdown?.options_key
        dropdownValues[dropdown?.options_key] = values[i]?.data[index] || values[i] || []
      })
      const tempOptions = {
        ...optionsData,
        ...dropdownValues,
      }
      setOptionsData(tempOptions)
    })
  }

  const fetchformFields = async () => {
    const { data = {} } = await apiCall({
      url: fetchFcFormUrl(),
      payload: { type: "TAG_FC_TO_PATIENT", patient_id: patientId },
      method: "POST",
    })
    setformFields(data)
    await getAllDropdownData(data || {})
  }

  const setInitialValues = () => {
    markForm.setFieldsValue(editData?.field_counsellor)
  }

  useEffect(() => {
    fetchformFields()
  }, [])

  useEffect(() => {
    setEditData(processData())
  }, [formFields])

  useEffect(() => {
    setInitialValues()
  }, [editData])

  const tagFcHandlingFunction = async (formData) => {
    const payload = {
      ...formData,
      patient_id: +patientId,
    }
    const { data = {} } = await apiCall({
      url: submitIndividualFcTagUrl(),
      method: "POST",
      payload,
    })
    const { message = "", error = false } = data
    if (!error) {
      if (message) {
        toastMessage.success({
          key: "updateFcIndividualTagSuccess",
          message: "Success",
          description: message,
        })
        if (userRole === "FIELD_COUNSELLOR") {
          navigate({ pathname: `/${currentSubdomain}/patient/dashboard` })
        } else {
          fetchProfile()
        }
      } else {
        toastMessage.error({
          key: "updateFcIndividualTagError",
          message: "Error",
          description: message,
        })
      }
      hideParentModal(true)
    }
  }

  const resetForm = () => {
    markForm.resetFields()
    hide()
  }

  const getFormBody = () => (
    <>
      <div className="fc-modal-form">
        <Row gutter={[16, 16]} align="middle">
          <Col span={12}>
            {formFields?.sections?.[0] && (
              <Form
                className="dynamic-form-fields-container"
                form={markForm}
                layout="vertical"
                onFinish={tagFcHandlingFunction}
              >
                <FormSectionBuilder
                  section={formFields.sections[0]}
                  fields={formFields.sections[0]?.steps}
                  optionsData={optionsData}
                  formObj={markForm}
                />
              </Form>
            )}
          </Col>
        </Row>
      </div>
      <div className="fc-update-form-btn-container">
        <div>
          <Button type="default" size="large" onClick={resetForm}>
            Cancel
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              markForm.submit()
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  )

  const renderModal = () => (
    <Modal width={600} open={openStatus} centered footer={null}>
      <div>{getFormBody()}</div>
    </Modal>
  )

  return (
    <>
      {contextHolder}
      {loading && <Loader size={40} type="semiTransparentFullPage" />}
      <div className="pv-action-modal-container">{renderModal()}</div>
    </>
  )
}
