export const bc = new BroadcastChannel("psp")

export const broadcastEvent = (event) => {
  const { eventName = "" } = event
  if (eventName) {
    bc.postMessage(event)
  }
}

const onEventHandler = (event) => {
  const { data = {} } = event
  const { eventName = "", details = {} } = data
  if (eventName === "navigateToUrl") {
    const { url = "" } = details
    if (url) {
      window.location.pathname = url
    }
  }
}

bc.onmessage = onEventHandler
