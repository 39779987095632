/**
 *
 * @returns string url
 */
export const fetchFcFormUrl = () => `/fieldCounsellor/fetch-form`
/**
 *
 * @returns string url
 */
export const fetchFcPatientDataUrl = () => `/fieldCounsellor/get-patients`
/**
 *
 * @returns string url
 */
export const getFcUploadDashboardDataUrl = () => `/fieldCounsellor/get-fc-upload-dashboard-data`
/**
 *
 * @returns string url
 */
export const getFcFormDropdownOptionsUrl = () => `/fieldCounsellor/get-fc-form-dropdown-options`
/**
 *
 * @returns string url
 */
export const submitIndividualFcTagUrl = () => `/fieldCounsellor/tag-individual-fc`
/**
 *
 * @returns string url
 */
export const createPatientInteractionUrl = () => `/fieldCounsellor/create-patient-interaction`
/**
 *
 * @returns string url
 */
export const editPatientInteractionUrl = () => `/fieldCounsellor/edit-patient-interaction`
/**
 *
 * @returns string url
 */
export const getPatientInteractionDashboardDataUrl = () =>
  `/fieldCounsellor/get-patient-interaction-dashboard-data`

/**
 *
 * @returns string url
 */
export const sampleDownloadSheetUrl = () => `/fieldCounsellor/get-sample-download-sheet`
/**
 *
 * @returns string url
 */
export const uploadFcBulkSheetUrl = () => `/fieldCounsellor/upload-fc-bulk-sheet`
/**
 *
 * @returns string url
 */
export const getTaskDataTableUrl = () => `/fieldCounsellor/get-task-dashboard-data`
/**
 *
 * @returns string url
 */
export const getTaskDropdownOptionsUrl = () => `/fieldCounsellor/get-task-dropdown-url`
/**
 *
 * @returns string url
 */
export const createTaskUrl = () => `/fieldCounsellor/create-task`
/**
 *
 * @returns string url
 */
export const editTaskUrl = () => `/fieldCounsellor/edit-task`
/**
 *
 * @returns string url
 */
export const updateTaskUrl = () => `/fieldCounsellor/update-task`
/**
 *
 * @returns string url
 */
export const fetchTaskFormUrl = () => `/fieldCounsellor/fetch-task-form`
