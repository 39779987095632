import { useState } from "react"
import { useSelector } from "react-redux"
import { Layout, Row, Col, Button, Tag } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { ProfileMenu } from "../../components/ProfileMenu"
import { ApplicationLogo } from "../../components/ApplicationLogo"
import { SideBar } from "../../components/SideBar"
// import { getMenuForRole } from "../../constants/sideMenu"
import {
  // getUserRole,
  getUserName,
  // getCurrentSubdomain,
  getCurrentApplicationRoleLabel,
  // hasAccessToAeModule,
  getAllSideMenuItems,
  getAllApplicationRoles,
} from "../../utils/common"
import "./styles.scss"
import ModuleMenu from "../../components/DLS/ModuleMenu"
import { SwitchRole } from "../../components/DLS/SwitchRole"
import { Loader } from "../../components/Loader"

const { Sider, Header, Content } = Layout
const isMobileView = window.innerWidth <= 768
export const PrivateAgentLayout = ({ children }) => {
  // const userRole = useSelector(getUserRole)
  const allRoles = useSelector(getAllApplicationRoles)
  const allRolesCount = allRoles?.length || 0
  const userHasMultipleRoles = allRolesCount > 1
  const userRoleLabel = useSelector(getCurrentApplicationRoleLabel)
  const userName = useSelector(getUserName)
  // const currentSubdomain = useSelector(getCurrentSubdomain)
  const SIDE_MENU = useSelector(getAllSideMenuItems)
  // const accessToAeModule = useSelector(hasAccessToAeModule)
  // const SIDE_MENU = getMenuForRole({
  //   userRole,
  //   currentSubdomain,
  //   accessToAeModule,
  //   displayCommunicationDashboard,
  // })
  const [collapsed, setCollapsed] = useState(true)
  const [loader, setLoader] = useState(false)
  const showLoader = () => {
    setCollapsed(true)
    setLoader(true)
  }
  const hideLoader = () => {
    setLoader(false)
  }

  const onSideBarCollapseClick = () => {
    setCollapsed(!collapsed)
  }
  return (
    <Layout className="applicationLayout">
      {isMobileView && (
        <Header>
          <Row align="middle" className="h-100">
            <Col span={isMobileView ? 4 : 6}>
              <ApplicationLogo />
            </Col>
            <Col span={isMobileView ? 4 : 6} className="text-right">
              <ProfileMenu />
            </Col>
            <Col span={4} className="text-right d-md-none module-menu-mobile-view">
              <ModuleMenu SIDE_MENU={SIDE_MENU} dropdownProps={{ placement: "bottomRight" }} />
            </Col>
          </Row>
        </Header>
      )}
      <Content>
        <Layout>
          <div className="side-bar-desktop-view">
            <Sider
              className="sidebar-container"
              collapsed={collapsed}
              trigger={null}
              width="max-content"
              collapsible
            >
              <div className={`sidebar-greetings-section${collapsed ? "-collapsed" : ""}`}>
                <ApplicationLogo />
                <div className="sidebar-greeting-username">{userName}</div>
                {!collapsed && (
                  <div>
                    <Tag className="sidebar-greeting-userrole">{userRoleLabel}</Tag>
                    {userHasMultipleRoles && <SwitchRole showLoader={showLoader} hideLoader={hideLoader} />}
                  </div>
                )}
              </div>
              <SideBar data={SIDE_MENU} />
              {loader && <Loader size={60} type="opaqueFullPage" />}
              <Button type="text" className="sidebar-trigger-custom" onClick={onSideBarCollapseClick}>
                {!collapsed && <LeftOutlined />}
                {collapsed && <RightOutlined />}
              </Button>
            </Sider>
          </div>
          <Content className="content-container">{children}</Content>
        </Layout>
      </Content>
    </Layout>
  )
}
