import { UserSwitchOutlined } from "@ant-design/icons"
import { Dropdown, Menu } from "antd"
import { useSelector } from "react-redux"
import { getAllApplicationRoles, getCurrentApplicationRoleLabel, getRoleLabel } from "../../../utils/common"
import { useRoleSwitch } from "../../../utils/hooks"

export const SwitchRole = ({ showLoader, hideLoader }) => {
  const allRoles = useSelector(getAllApplicationRoles)
  const currentRoleAppLabel = useSelector(getCurrentApplicationRoleLabel)
  const [switchRole] = useRoleSwitch({ showLoader, hideLoader })
  const getRolesMenu = () =>
    allRoles.map((roleAppObj, index) => {
      const label = getRoleLabel(roleAppObj)
      return label !== currentRoleAppLabel
        ? {
            key: index,
            label,
            disabled: label === currentRoleAppLabel,
          }
        : null
    })
  const onRoleClick = async ({ key }) => {
    showLoader()
    await switchRole({ key })
  }
  const menu = <Menu items={getRolesMenu()} onClick={onRoleClick} />
  return (
    <Dropdown overlay={menu}>
      <UserSwitchOutlined />
    </Dropdown>
  )
}
