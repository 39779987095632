import { Layout } from "antd"
import { node } from "prop-types"

/**
 *
 * @description This is Layout component for guest routes
 */
export const GuestLayout = ({ children }) => <Layout className="guest-layout-container">{children}</Layout>

GuestLayout.propTypes = {
  children: node.isRequired,
}
